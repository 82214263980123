import React, { useState, useEffect } from 'react'
import { Animated } from 'react-animated-css'
import { Tooltip, Badge } from 'antd'

import MicIcon from 'App/Assets/SVG/MicIcon'
import CameraIcon from 'App/Assets/SVG/CameraIcon'
import ChatIcon from 'App/Assets/SVG/ChatIcon'
import ShareScreenIcon from 'App/Assets/SVG/ShareScreenIcon'
import PaperClipOutlined from '@ant-design/icons/PaperClipOutlined'

import styles from '../../AppointmentScreenStyles.module.css'

export const VideoControls = ({
  isMuted,
  isBlinded,
  isChat,
  isShareScreen,
  disabledButtons,
  newMessage,
  handleShowFiles,
  handleMute,
  handleBlind,
  handleShowChat,
  handleShareScreen,
  isShowFiles,
  isVisible,
  videoDevicesLength,
  cameraNumber,
}) => {
  const [totalCamerasDots, setTotalCamerasDots] = useState([])

  useEffect(() => {
    if (videoDevicesLength > 1) {
      setTotalCamerasDots([])
      const arr = []
      for (let i = 0; i < videoDevicesLength; i++)
        if (i === cameraNumber - 1) arr.push({ id: i, active: true })
        else arr.push({ id: i, active: false })
      setTotalCamerasDots(arr)
    }
  }, [cameraNumber, videoDevicesLength])

  return (
    <Animated
      className={styles.videoControlsContainer}
      animationIn="fadeIn"
      animationOut="fadeOut"
      isVisible={isVisible}>
      {window.innerWidth < 800 ? (
        <>
          <div
            className={styles.videoControlsCircle}
            style={{
              background: disabledButtons
                ? '#b6b4b4'
                : isShowFiles
                ? '#EC3832'
                : 'white',
            }}
            onClick={!disabledButtons && handleShowFiles}>
            <div className={styles.videoControlsIconContainer}>
              <PaperClipOutlined
                style={{
                  fontSize: '30px',
                  color: disabledButtons
                    ? '#b6b4b4'
                    : isShowFiles
                    ? 'white'
                    : '#6178FA',
                }}
              />
            </div>
          </div>
          <div
            className={styles.videoControlsCircle}
            style={{
              background: disabledButtons
                ? '#b6b4b4'
                : isMuted
                ? '#EC3832'
                : 'white',
            }}
            onClick={!disabledButtons && handleMute}>
            <div className={styles.videoControlsIconContainer}>
              <MicIcon
                width="30px"
                height="30px"
                fill={isMuted ? 'white' : '#6078FB'}
              />
            </div>
          </div>
          <div
            className={styles.videoControlsCircle}
            style={{
              background: disabledButtons
                ? '#b6b4b4'
                : isBlinded
                ? '#EC3832'
                : 'white',
            }}
            onClick={!disabledButtons && handleBlind}>
            <div
              className={styles.videoControlsIconContainer}
              style={{ position: 'relative' }}>
              <CameraIcon
                width="30px"
                height="30px"
                fill={isBlinded ? 'white' : '#6078FB'}
              />
              {videoDevicesLength > 1 && (
                <div
                  style={{
                    position: 'absolute',
                    top: '-35px',
                    display: 'flex',
                  }}>
                  {totalCamerasDots.map(dots => {
                    return (
                      <div
                        key={dots.id}
                        className={
                          dots.active
                            ? styles.videoControlsCameraDotsBlue
                            : styles.videoControlsCameraDotsWhite
                        }
                      />
                    )
                  })}
                </div>
              )}
            </div>
          </div>
          <div
            className={styles.videoControlsCircle}
            style={{
              background: disabledButtons
                ? '#b6b4b4'
                : isChat
                ? '#EC3832'
                : 'white',
              position: 'relative',
            }}
            onClick={!disabledButtons && handleShowChat}>
            <div className={styles.videoControlsIconContainer}>
              <ChatIcon
                width="30px"
                height="30px"
                fill={isChat ? 'white' : '#6078FB'}
              />
            </div>
            {!isChat && newMessage && (
              <div style={{ position: 'absolute', top: '0', right: '5%' }}>
                <Badge count={'!'} style={{ backgroundColor: '#52c41a' }} />
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <Tooltip title="Mostrar/Esconder Archivos">
            <div
              className={styles.videoControlsCircle}
              style={{
                background: disabledButtons
                  ? '#b6b4b4'
                  : isShowFiles
                  ? '#EC3832'
                  : 'white',
              }}
              onClick={!disabledButtons && handleShowFiles}>
              <div className={styles.videoControlsIconContainer}>
                <PaperClipOutlined
                  style={{
                    fontSize: '30px',
                    color: disabledButtons
                      ? '#b6b4b4'
                      : isShowFiles
                      ? 'white'
                      : '#6178FA',
                  }}
                />
              </div>
            </div>
          </Tooltip>
          <Tooltip title="Encender/Apagar Micrófono">
            <div
              className={styles.videoControlsCircle}
              style={{
                background: disabledButtons
                  ? '#b6b4b4'
                  : isMuted
                  ? '#EC3832'
                  : 'white',
              }}
              onClick={!disabledButtons && handleMute}>
              <div className={styles.videoControlsIconContainer}>
                <MicIcon
                  width="30px"
                  height="30px"
                  fill={isMuted ? 'white' : '#6078FB'}
                />
              </div>
            </div>
          </Tooltip>
          <Tooltip title="Encender/Apagar Cámara">
            <div
              className={styles.videoControlsCircle}
              style={{
                background: disabledButtons
                  ? '#b6b4b4'
                  : isBlinded
                  ? '#EC3832'
                  : 'white',
              }}
              onClick={!disabledButtons && handleBlind}>
              <div
                className={styles.videoControlsIconContainer}
                style={{ position: 'relative' }}>
                <CameraIcon
                  width="30px"
                  height="30px"
                  fill={isBlinded ? 'white' : '#6078FB'}
                />
                {videoDevicesLength > 1 && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '-35px',
                      display: 'flex',
                    }}>
                    {totalCamerasDots.map(dots => {
                      return (
                        <div
                          key={dots.id}
                          className={
                            dots.active
                              ? styles.videoControlsCameraDotsBlue
                              : styles.videoControlsCameraDotsWhite
                          }
                        />
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
          </Tooltip>

          <Tooltip title="Ver Chat">
            <div
              className={styles.videoControlsCircle}
              style={{
                background: disabledButtons
                  ? '#b6b4b4'
                  : isChat
                  ? '#EC3832'
                  : 'white',
                position: 'relative',
              }}
              onClick={!disabledButtons && handleShowChat}>
              <div className={styles.videoControlsIconContainer}>
                <ChatIcon
                  width="30px"
                  height="30px"
                  fill={isChat ? 'white' : '#6078FB'}
                />
              </div>
              {!isChat && newMessage && (
                <div style={{ position: 'absolute', top: '0', right: '5%' }}>
                  <Badge count={'!'} style={{ backgroundColor: '#52c41a' }} />
                </div>
              )}
            </div>
          </Tooltip>
          <Tooltip
            title={isShareScreen ? 'Dejar de Compartir' : 'Compartir Pantalla'}>
            <div
              className={styles.videoControlsCircle}
              style={{
                background: disabledButtons
                  ? '#b6b4b4'
                  : isShareScreen
                  ? '#EC3832'
                  : 'white',
                position: 'relative',
              }}
              onClick={!disabledButtons && handleShareScreen}>
              <div className={styles.videoControlsIconContainer}>
                <ShareScreenIcon
                  width="30px"
                  height="30px"
                  fill={isShareScreen ? 'white' : '#6078FB'}
                />
              </div>
            </div>
          </Tooltip>
        </>
      )}
    </Animated>
  )
}

export default VideoControls
