import React from 'react'
import { ApolloProvider } from 'react-apollo'

const ApolloApp = ({ children, client }) => {
  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>

  )
}

export default ApolloApp