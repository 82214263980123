const subscribeIsMutedAbly = (channelListener, setMessageDoctorMuted) => {
  channelListener.subscribe(`isMutedPatient`, message => {
    const { isMuted } = message.data
    setMessageDoctorMuted(isMuted)
  })
}

const subscribeIsBlindedAbly = (channelListener, setMessageDoctorBlinded) => {
  channelListener.subscribe(`isBlindedPatient`, message => {
    const { isBlinded } = message.data

    setMessageDoctorBlinded(isBlinded)
  })
}

export { subscribeIsMutedAbly, subscribeIsBlindedAbly }
