import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Client } from '@twilio/conversations'
import { v4 as uuidv4 } from 'uuid'
import { Input, Button, Spin, Drawer } from 'antd'
import ScrollableFeed from 'react-scrollable-feed'
import SendIcon from 'App/Assets/SVG/SendIcon'
import styles from '../../AppointmentScreenStyles.module.css'
import { CloseOutlined } from '@ant-design/icons'

const twilioMessageToKendoMessage = message => ({
  text: message.body,
  author: { id: message.author, name: message.author },
  timestamp: message.timestamp,
})

const ChatApp = ({
  username,
  patient,
  channelName,
  token,
  showChat,
  handleShowChat,
  handleNewMessage,
}) => {
  const [error, setError] = useState(null)
  const [client, setClient] = useState(null)
  const [conversation, setConversation] = useState(null)
  const [initialLoad, setInitialLoad] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [messages, setMessages] = useState([])
  const [textValue, setTextValue] = useState('')
  const [patientClientId] = useState(
    patient.firstname.concat(' ', patient.lastname),
  )
  const [user] = useState({ id: username, name: username })

  const messagesRef = useRef(messages)

  const handleError = error => {
    console.error(error)
    setError('Could not load chat')
  }

  const handleTextInput = evt => {
    if (evt.target.value[0] !== ' ') setTextValue(evt.target.value)
  }

  const handleSubmit = () => {
    if (textValue !== '') {
      conversation.sendMessage(textValue)
      setTextValue('')
    }
  }

  const MessageTemplate = ({ item }) => {
    if (
      item &&
      item.author &&
      item.author.id &&
      item.author.id !== user &&
      user.id
    )
      return item.author.id !== user.id ? (
        <div className={styles.chatBoxMessage}>
          <div className={styles.chatBoxMessageBody}>{item && item.text}</div>
        </div>
      ) : (
        <div className={styles.chatBoxMessageMine}>
          <div className={styles.chatBoxMessageBodyMine}>
            {item && item.text}
          </div>
        </div>
      )
  }

  const messageAdded = useCallback(
    async message => {
      messagesRef.current = [
        ...messagesRef.current,
        twilioMessageToKendoMessage(message),
      ]
      if (
        messagesRef.current[messagesRef.current.length - 1].author.id !==
        user.id
      )
        handleNewMessage(true)
      setMessages(messagesRef.current)
    },
    [handleNewMessage, user.id],
  )

  useEffect(() => {
    const initClient = async () => {
      const client = await new Client(token)

      client.on('stateChanged', state => {
        if (state === 'initialized') {
          setClient(client)
        }
      })

      await client.getSubscribedConversations().then(async paginator => {
        if (paginator.items.length > 0)
          for (let i = 0; i < 1; i++)
            if (paginator.items[i]) {
              const conversations = paginator.items[paginator.items.length - 1]
              if (
                conversations.state &&
                conversations.state.uniqueName &&
                channelName !== conversations.state.uniqueName
              )
                await conversations.leave()
            }
      })
    }

    token && initClient()
  }, [username, token, channelName])

  useEffect(() => {
    const setupClientConversations = async client => {
      let error
      for (let i = 0; i < 3; i++)
        try {
          const conversations = await client.getConversationByUniqueName(
            channelName,
          )

          return setConversation(conversations)
        } catch (err) {
          if (err && err.body && err.body.code && err.body.code === 50350) {
            const conversations = await client
              .createConversation({ uniqueName: channelName })
              .catch(err => console.error(err))
            if (conversations !== undefined) {
              await conversations
                .add(patientClientId)
                .catch(err => console.error(err))
            } else error = err
          } else {
            console.error('code', err.body)
            error = err
          }
        }
      handleError(error)
    }

    if (client) setupClientConversations(client)

    return async () => {
      if (client) await client.shutdown()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, channelName])

  useEffect(() => {
    const messagesLoaded = async messagePage => {
      const messages = await messagePage.items.map(twilioMessageToKendoMessage)
      messagesRef.current = messages

      setMessages(messages)
    }

    const setupConversation = async conversation => {
      if (
        (conversation &&
          conversation.state &&
          conversation.state.status &&
          conversation.state.status !== 'joined') |
        (conversation && conversation.status !== 'joined')
      )
        await conversation.join()
      const messages = await conversation.getMessages().then()

      messagesLoaded(await messages)

      conversation.on('messageAdded', await messageAdded)
    }
    if (conversation !== null && !initialLoad) {
      setIsLoading(false)
      setInitialLoad(true)
      setupConversation(conversation)
    }
  }, [conversation, messages, messageAdded, initialLoad])

  useEffect(() => {
    if (showChat) handleNewMessage(false)
  }, [handleNewMessage, showChat])

  if (error) return <p>{error}</p>
  if (isLoading)
    return (
      <div className={styles.loadingContainer}>
        <Spin size="large" />
      </div>
    )

  const messageRender = messages.map(
    message => message && <MessageTemplate key={uuidv4()} item={message} />,
  )

  return (
    <>
      {showChat ? (
        <Drawer
          width={
            window.innerWidth > 1200
              ? '25%'
              : window.innerWidth < 1200 && window.innerWidth > 1100
              ? '32%'
              : window.innerWidth < 1100 && window.innerWidth > 800
              ? '35%'
              : window.innerWidth < 800 && '100%'
          }
          placement="right"
          closable={false}
          onClose={handleShowChat}
          visible={showChat}
          className={styles.chatDrawer}
          getContainer={false}
          style={{ position: 'absolute' }}>
          <div className={styles.chatBox}>
            {window.innerWidth < 800 ? (
              <CloseOutlined
                onClick={() => handleShowChat()}
                style={{ alignSelf: 'flex-end', cursor: 'pointer' }}
              />
            ) : null}
            <div className={styles.chatBoxMessages}>
              <ScrollableFeed className={styles.scrollableFeed} forceScroll>
                {messageRender}
              </ScrollableFeed>
            </div>
            <div className={styles.chatBoxInput}>
              <div className={styles.chatBoxInputArea}>
                <div className={styles.chatBoxInputText}>
                  <Input
                    value={textValue}
                    className={styles.chatBoxInputComponent}
                    onPressEnter={handleSubmit}
                    onChange={handleTextInput}
                    size="large"
                    placeholder="Escribe tu mensaje..."
                  />
                </div>
                <div className={styles.chatBoxInputSubmit}>
                  <Button
                    size="large"
                    onClick={handleSubmit}
                    className={styles.chatBoxInputSubmitButton}>
                    <SendIcon width="15px" height="15px" fill="white" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Drawer>
      ) : null}
    </>
  )
}
export default ChatApp
