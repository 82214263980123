import React, { useState, useEffect } from 'react'
import { Card, Form, Popover, Steps, Button, Row, Col } from 'antd'
import {
  LeftOutlined,
  RightOutlined,
  SendOutlined,
  StopOutlined,
} from '@ant-design/icons'
import styles from './../AppointmentDesktopSurveyStyles.module.css'

import { stepsContent, stepsOptions } from './Steps'
const { Step } = Steps

const SurveyStepper = ({ handleOpinions, isLoading }) => {
  // hooks
  const [current, setCurrent] = useState(0)
  const [stepOneOpinions, setStepOneOpinions] = useState([])
  const [stepTwoOpinions, setStepTwoOpinions] = useState([])
  const [stepThreeOpinions, setStepThreeOpinions] = useState([])
  const [stepFourthOpinions, setStepFourthOpinions] = useState('')
  const [isValidForm, setIsValidForm] = useState(false)
  // variables
  const {
    StepOneContent,
    StepTwoContent,
    StepThreeContent,
    StepFourthContent,
  } = stepsContent
  const { stepOneOptions, stepTwoOptions, stepThreeOptions } = stepsOptions
  const onChange = current => setCurrent(current)

  const { Item } = Form

  useEffect(() => {
    if (
      stepOneOpinions.length > 0 ||
      stepTwoOpinions.length > 0 ||
      stepThreeOpinions.length > 0 ||
      stepFourthOpinions.length > 0
    )
      setIsValidForm(true)
    else setIsValidForm(false)
  }, [stepOneOpinions, stepTwoOpinions, stepThreeOpinions, stepFourthOpinions])
  const next = () => setCurrent(current + 1)
  const prev = () => setCurrent(current - 1)
  const handleStepOneOptions = checkedValues => {
    setStepOneOpinions(checkedValues)
  }
  const handleStepTwoOptions = checkedValues => {
    setStepTwoOpinions(checkedValues)
  }
  const handleStepThreeOptions = checkedValues => {
    setStepThreeOpinions(checkedValues)
  }
  const handleStepFourthOptions = inputText => {
    if (inputText.target.value !== '')
      setStepFourthOpinions(inputText.target.value)
    else setStepFourthOpinions('')
  }

  const steps = [
    {
      title: 'Audio',
      content: (
        <StepOneContent
          stepOneOpinions={stepOneOpinions}
          stepOneOptions={stepOneOptions}
          handleStepOneOptions={handleStepOneOptions}
        />
      ),
    },
    {
      title: 'Video',
      content: (
        <StepTwoContent
          stepTwoOpinions={stepTwoOpinions}
          stepTwoOptions={stepTwoOptions}
          handleStepTwoOptions={handleStepTwoOptions}
        />
      ),
    },
    {
      title: 'Presentación',
      content: (
        <StepThreeContent
          stepThreeOpinions={stepThreeOpinions}
          stepThreeOptions={stepThreeOptions}
          handleStepThreeOptions={handleStepThreeOptions}
        />
      ),
    },
    {
      title: 'Otros',
      content: (
        <StepFourthContent
          style={{ height: '142px' }}
          stepFourthOpinions={stepFourthOpinions}
          handleOpinions={handleOpinions}
          handleStepFourthOptions={handleStepFourthOptions}
        />
      ),
    },
  ]

  const handleClick = isValid => {
    if (!isValid) handleOpinions(null, null)
    else
      handleOpinions(stepFourthOpinions, [
        ...stepOneOpinions,
        ...stepTwoOpinions,
        ...stepThreeOpinions,
      ])
  }

  const customDot = (dot, { status, index }) => (
    <Popover
      content={
        <span>
          Paso {index + 1}:{' '}
          {status === 'process'
            ? 'en proceso'
            : status === 'finish'
            ? 'listo'
            : 'en espera'}
        </span>
      }>
      {dot}
    </Popover>
  )
  return (
    <Form>
      <Row className={styles.stepsHeaderGap}>
        <Col xs={4} style={{ margin: '0px auto' }}>
          <Steps
            progressDot={customDot}
            current={current}
            onChange={onChange}
            className={styles.marginLeftOverPx}>
            {steps.map(({ title }) => (
              <Step key={title} title={title} className={styles.dot} />
            ))}
          </Steps>
        </Col>
      </Row>
      <Row
        type="flex"
        justify="center"
        align="middle"
        className={styles.stepsHeaderGap}>
        <Col>
          <Row type="flex" justify="center" align="middle">
            <Col
              xs={2}
              sm={4}
              md={6}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              disabled={current === 0}
              onClick={prev}>
              <Button
                className={styles.borderNone}
                type="secondary"
                icon={<LeftOutlined />}
                onClick={prev}
                disabled={current === 0}
              />
            </Col>
            <Col
              xs={12}
              sm={16}
              md={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}>
              <Card className={styles.antCard}>
                <h2 className={styles.whatIsProblemTitle}>
                  ¿Qué problema hubo?
                </h2>
                <div className={styles.stepsContent}>
                  <Item>{steps[current].content}</Item>
                </div>
              </Card>
            </Col>
            <Col
              xs={2}
              sm={4}
              md={6}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Button
                className={styles.borderNone}
                type="secondary"
                icon={<RightOutlined />}
                onClick={next}
                disabled={current === steps.length - 1}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex" justify="center" align="middle">
        <Col className={styles.stepsActions}>
          <Button
            style={{ marginRight: '2px' }}
            className={styles.sendSurveyButton}
            shape="round"
            icon={isValidForm ? <SendOutlined /> : <StopOutlined />}
            loading={isLoading || false}
            type="primary"
            onClick={() => handleClick(isValidForm)}>
            {isValidForm ? 'Enviar' : 'Omitir'}
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default SurveyStepper
