import Auth from '@aws-amplify/auth'

export default async () => {
  const user = await Auth.currentAuthenticatedUser()

  console.log(user.signInUserSession.idToken.jwtToken)

  return {
    Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
  }
}
