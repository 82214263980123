import { useQuery } from 'react-apollo'
import { GET_UPLOAD_FETCH_URL } from 'App/Queries'

const useGetUploads = key => {
  const { data, loading, error } = useQuery(GET_UPLOAD_FETCH_URL, {
    variables: { key },
    fetchPolicy: 'network-only',
  })

  return {
    data,
    loading,
    error,
  }
}

export default useGetUploads
