import React from 'react'
import { Input } from 'antd'

const { TextArea } = Input

const StepFourthContent = ({ stepFourthOpinions, handleStepFourthOptions, handleOpinions }) => {
  return (
    <TextArea
      rows={2}
      placeholder="Cuéntanos más"
      maxLength={500}
      autoSize={{ minRows: 2, maxRows: 6 }}
      defaultValue={stepFourthOpinions}
      onChange={handleStepFourthOptions}
      onPressEnter={handleOpinions}
    />
  )
}

export default StepFourthContent
