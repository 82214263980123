import React, { useState } from 'react'
import { Row, Col, message, Button } from 'antd'
import { useMutation } from '@apollo/react-hooks'
import PropTypes from 'prop-types'
import SurveyStepper from './StepperContent'
import styles from './AppointmentDesktopSurveyStyles.module.css'

import { ADD_REVIEW_APPOINTMENT } from 'App/Mutators'

const AppointmentSurvey = ({ doctorSession, appointmentId, exitCall }) => {
  // hooks
  const [isLoading, setIsLoading] = useState(null)
  const [showSurvey, setShowSurvey] = useState(false)

  // variables

  // functions
  const [addReviewAppointment] = useMutation(ADD_REVIEW_APPOINTMENT)

  const handleOpinions = async (stepFourthOpinions, issues) => {
    setIsLoading(true)
    const reviewData = {
      comment: stepFourthOpinions ? stepFourthOpinions : null,
      issues: issues ? [...issues] : [],
    }
    try {
      await addReviewAppointment({
        variables: { _id: appointmentId, input: reviewData },
      })
    } catch (err) {
      setIsLoading(false)
      console.log('Error message::', err)
    }
    message.success('Encuesta completada')
    setTimeout(() => {
      setIsLoading(false)
      exitCall()
    }, 800)
  }

  const handleBack = () => {
    exitCall()
  }

  return (
    <div
      style={{
        textAlign: 'initial',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        height: '75%',
      }}>
      <h2 className={styles.ratingTitle}>
        Dr. <strong>{doctorSession.lastname}</strong>
        {`, la consulta ha finalizado!`}
      </h2>
      {!showSurvey ? (
        <Row type="flex" justify="center" align="middle">
          <Col span={24} style={{ textAlign: 'center' }}>
            <h3 className={styles.ratingSubTitle}>
              {' '}
              ¿Tuvo algún problema con la plataforma?{' '}
            </h3>
            <Row
              type="flex"
              justify="center"
              align="middle"
              style={{ marginTop: 30 }}>
              <Button
                type="primary"
                onClick={() => handleBack()}
                style={{ width: 100, marginRight: 20 }}>
                No
              </Button>
              <Button
                type="danger"
                onClick={() => setShowSurvey(true)}
                style={{ width: 100 }}>
                Si
              </Button>
            </Row>
          </Col>
        </Row>
      ) : (
        <>
          <h3 className={styles.ratingSubTitle}>
            Seleccione los problemas que tuvo y/o deje un comentario con su
            problema.
          </h3>
          <SurveyStepper
            style={{ marginTop: '10px' }}
            handleOpinions={handleOpinions}
            isLoading={isLoading}
          />
        </>
      )}
    </div>
  )
}

AppointmentSurvey.propTypes = {
  doctorSession: PropTypes.object.isRequired,
  appointmentId: PropTypes.string.isRequired,
  exitCall: PropTypes.func.isRequired,
}

export default AppointmentSurvey
