import React, { useEffect } from 'react'
import { Layout, Breadcrumb, Row, Col } from 'antd'
import { withRouter } from 'react-router-dom'
import { Auth } from 'aws-amplify'
const AppLayout = ({ history, children }) => {
  useEffect(() => {
    Auth.currentSession()
      .then(data => {})
      .catch(err => {
        console.log(err)
        return history.push('/login')
      })
  }, [history])

  return (
    <Layout
      style={{
        height: '100vh',
      }}>
      <Row style={{ height: '100vh' }} justify="space-around" align="middle">
        <Col md={24}>
          <Row style={{}} justify="space-around" align="middle">
            <Col md={24} style={{ height: '100vh', backgroundColor: 'white' }}>
              <Breadcrumb></Breadcrumb>
              {children}
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  )
}

export default withRouter(AppLayout)
