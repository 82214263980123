import React, { useState, useEffect } from 'react'
import { Menu, Dropdown, Spin, Avatar } from 'antd'
import { DownOutlined, PoweroffOutlined } from '@ant-design/icons'
import { Auth } from 'aws-amplify'
import { withRouter, useLocation } from 'react-router-dom'
import styles from './LogOutModalStyles.module.css'

const LogOutModal = ({ history, username }) => {
  const location = useLocation()
  const [loadingLogOut, setLoadingLogOut] = useState(false)
  const [avatarLetter, setAvatarLetter] = useState('')
  const [randomAvatarColor, setRandomAvatarColor] = useState('')
  const getFirstLetter = userName => {
    return userName[0].toUpperCase()
  }
  const randomColor = () => {
    const letters = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i++) color += letters[Math.floor(Math.random() * 16)]

    return color
  }
  const logOut = () => {
    setLoadingLogOut(true)
    Auth.signOut({ global: true })
      .then(() => {
        setLoadingLogOut(false)
        history.push('/login')
      })
      .catch(err => {
        setLoadingLogOut(false)
        history.push('/login')
      })
  }
  useEffect(() => {
    setAvatarLetter(getFirstLetter(username))
    setRandomAvatarColor(randomColor)
  }, [username])
  const menu = (
    <Menu style={{ boxShadow: '10px 10px 44px 5px rgba(0,0,0,0.2)' }}>
      <Menu.Item onClick={logOut}>
        {loadingLogOut ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '12%',
            }}>
            <Spin />
          </div>
        ) : (
          <>
            <PoweroffOutlined /> Cerrar Sesión
          </>
        )}
      </Menu.Item>
    </Menu>
  )

  if (location.pathname.startsWith('/appointment')) return null

  return (
    <>
      <Dropdown overlay={menu}>
        <a
          className={styles.nameLogOut}
          href="#/"
          onClick={e => e.preventDefault()}>
          <Avatar
            size={35}
            style={{
              marginRight: '10px',
              fontSize: '20px',
              background: randomAvatarColor,
            }}>
            {avatarLetter}
          </Avatar>
          {username} <DownOutlined />
        </a>
      </Dropdown>
    </>
  )
}

export default withRouter(LogOutModal)
