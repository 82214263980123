import * as React from 'react'

function AttachmentIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      fill={props.fill}
      {...props}
      viewBox="0 0 11.413 10.158">
      <defs></defs>
      <path
        className="a"
        d="M4.259,10.478a3.271,3.271,0,0,1-2.1-.746,2.27,2.27,0,0,1,.129-3.688L8.339.929A2.455,2.455,0,0,1,10.478.379a2.058,2.058,0,0,1,1.531,1.295,1.68,1.68,0,0,1-.649,1.81L5.577,8.376A1.949,1.949,0,0,1,4.5,8.854a1.326,1.326,0,0,1-.974-.277c-.449-.382-.514-1.1.235-1.729L7.821,3.412a.484.484,0,0,1,.6,0,.324.324,0,0,1,0,.511L4.363,7.359c-.351.3-.383.58-.234.707a.377.377,0,0,0,.28.069,1.067,1.067,0,0,0,.564-.27l5.783-4.892a1.034,1.034,0,0,0,.428-1.112,1.247,1.247,0,0,0-.926-.784,1.5,1.5,0,0,0-1.315.363L2.9,6.556a1.627,1.627,0,0,0-.129,2.665,2.4,2.4,0,0,0,3.15-.11L11.964,4a.484.484,0,0,1,.6,0,.324.324,0,0,1,0,.511L6.521,9.623a3.479,3.479,0,0,1-2.263.855Z"
        transform="translate(-1.28 -0.32)"
      />
    </svg>
  )
}

export default AttachmentIcon
