import React, { useEffect, useReducer } from 'react'

import {
    List,
    Tag,
    Button,
    Spin
} from 'antd'

import TagFiles from './TagFiles'

const TagStatus = ({ color, label, styles }) => {
    return (
        <Tag className={styles.tagListItem}>
            <div className={styles.taglistContent}>
                <div className={styles.tagDot} style={{ backgroundColor: color }}></div>
                <div className={styles.tagLabel}>{label}</div>
            </div>
        </Tag>
    )
}

const switchAppointment = (state, action) => {
    switch (action.type) {
        case 'UPDATE_APPOINTMENT':
            return {
                appointment: action.appointment
            }

        default:
            return {
                appointment: action.appointment
            }
    }
}

const Item = ({ cita, index, styles, setShowFiles, fillFiles, limit, handleClick, ablyState, setAppInfoStatus }) => {

    const conectedColor = '#5CC531'
    const waitingColor = '#EFB041'
    const disconectedColor = '#979797'
    const endColor = '#6A7BFF'
    const listWaitingColor = 'rgba(239, 176, 65, 0.64)'
    const listConectedColor = '#4aff0047'
    const listFinishedColor = 'rgba(107, 124, 255, 0.69)'
    const listDisconectedColor = 'rgba(151, 151, 151, 0.68)'
    const listEmergencyColor = 'rgba(211, 47, 47, 0.45)'

    const initialState = {
        appointment: cita
    }

    const [state, dispatch] = useReducer(
        switchAppointment,
        initialState,
    )

    useEffect(() => {
        if (cita.appointment !== undefined && ablyState.waitRoomId !== null) {

            const { closedAt } = cita.appointment

            if (closedAt)
                cita.appointment.statusConnection = 'done'
            else {
                const isOnline = ablyState.waitRoomId.find(p => {
                    if (!cita.appointment) return false
                    return p.clientId === cita.appointment.patient._id
                })

                if (isOnline && isOnline.data === 'waiting')
                    cita.appointment.statusConnection = 'waiting'
                else
                    cita.appointment.statusConnection = isOnline ? 'connected' : 'disconnected'

            }

            setAppInfoStatus(cita.appointment.statusConnection)
            dispatch({
                type: 'UPDATE_APPOINTMENT',
                appointment: cita
            })

        } else
            dispatch({
                type: 'UPDATE_APPOINTMENT',
                appointment: cita
            })



        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ablyState.waitRoomId])

    useEffect(() => {
        if (cita.appointment !== undefined && ablyState.waitRoomId !== null) {

            const { closedAt } = cita.appointment

            if (closedAt)
                cita.appointment.statusConnection = 'done'
            else {
                const isOnline = ablyState.waitRoomId.find(p => {
                    if (!cita.appointment) return false
                    return p.clientId === cita.appointment.patient._id
                })

                if (isOnline && isOnline.data === 'waiting')
                    cita.appointment.statusConnection = 'waiting'
                else
                    cita.appointment.statusConnection = isOnline ? 'connected' : 'disconnected'

            }

            setAppInfoStatus(cita.appointment.statusConnection)
            dispatch({
                type: 'UPDATE_APPOINTMENT',
                appointment: cita
            })

        } else
            dispatch({
                type: 'UPDATE_APPOINTMENT',
                appointment: cita
            })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cita])

    if (state.appointment !== null)
        return (
            <>
                <List.Item
                    className={styles.rowItemList}
                    style={{
                        height: state.appointment.appointment ? '130px' : '60px',
                        marginBottom: '-14px',
                        paddign: '20px',
                    }}>
                    <div
                        className={styles.listItemTextHour}
                        style={{
                            padding: state.appointment.appointment ? '30px 20px' : '20px',
                            color: state.appointment.appointment ? '#696969' : '#69696980',
                            fontWeight: state.appointment.appointment && '600',
                        }}>
                        {state.appointment.time} hrs
                    </div>
                    <div
                        className={styles.rowItemListInline}
                        style={{
                            background: state.appointment.appointment
                                ? state.appointment.appointment.statusConnection === 'waiting'
                                    ? listWaitingColor
                                    : state.appointment.appointment.statusConnection === 'connected'
                                        ? listConectedColor
                                        : state.appointment.appointment.statusConnection === 'disconnected'
                                            ? listDisconectedColor
                                            : state.appointment.appointment.statusConnection === 'emergency'
                                                ? listEmergencyColor
                                                : listFinishedColor
                                : '#F9F9F9',
                            padding: state.appointment.appointment ? '30px 20px' : '20px',
                        }}>
                        {state.appointment.appointment ? (
                            <div className={styles.itemAppointmentList}>
                                <div className={styles.dataPatientItem}>
                                    <div className={styles.namePatientItem}>
                                        {state.appointment.appointment.patient.firstname}{' '}
                                        {state.appointment.appointment.patient.lastname}
                                    </div>
                                    <div className={styles.rutItem}>
                                        {state.appointment.appointment.patient.rut
                                            ? state.appointment.appointment.patient.rut
                                            : state.appointment.appointment.patient.passport}
                                    </div>
                                    <div className={styles.tagsItem}>
                                        <div className={styles.statusItem}>
                                            {state.appointment.appointment.statusConnection === 'waiting' ? (
                                                <TagStatus styles={styles}
                                                    label="Esperando"
                                                    color={waitingColor}
                                                />
                                            ) : state.appointment.appointment.statusConnection === 'connected' ? (
                                                <TagStatus styles={styles}
                                                    label="Conectado"
                                                    color={conectedColor}
                                                />
                                            ) : state.appointment.appointment.statusConnection === 'disconnected' ? (
                                                <TagStatus styles={styles}
                                                    label="Desconectado"
                                                    color={disconectedColor}
                                                />
                                            ) : (
                                                            <TagStatus styles={styles} label="Finalizado" color={endColor} />
                                                        )}
                                        </div>
                                        <div
                                            className={styles.filesItem}
                                            onClick={() => {
                                                setShowFiles(true)
                                                fillFiles(index)
                                            }}>
                                            <TagFiles
                                                item={cita}
                                                styles={styles}
                                                ablyState={ablyState}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.startMeetButton}>
                                    <Button
                                        type="primary"
                                        shape="round"
                                        disabled={
                                            !(
                                                state.appointment.appointment.statusConnection === 'waiting' ||
                                                state.appointment.appointment.statusConnection === 'connected'
                                            )
                                        }
                                        onClick={() => handleClick(cita)}>
                                        Ingresar a consulta
                        </Button>
                                </div>
                            </div>
                        ) : (
                                <p
                                    className={styles.listItemText}
                                    style={{ opacity: 0.5 }}></p>
                            )}
                    </div>
                </List.Item>
                {index === limit ? (
                    <div className={styles.listSeparatorLine} />
                ) : null}
            </>
        )
    else
        return (
            <>
                <List.Item className={styles.rowItemList}
                    style={{
                        height: '60px',
                        marginBottom: '-14px',
                        paddign: '20px',
                    }}>
                    <Spin></Spin>
                </List.Item>
            </>
        )

}

export default Item