const publishShareScreenAbly = (
  channel,
  doctorAcceptSharingScreen,
  isShareScreen,
) => {
  if (isShareScreen)
    if (doctorAcceptSharingScreen)
      setTimeout(() => {
        channel.publish('sharingScreen', {
          pressButton: 'confirm',
          animation: true,
        })
      }, 1800)
    else
      channel.publish('sharingScreen', {
        pressButton: 'none',
        animation: false,
      })
  else
    setTimeout(() => {
      channel.publish('sharingScreen', {
        pressButton: 'cancel',
        animation: false,
      })
    }, 1800)
}

const publishIsBlindedAbly = (channel, isBlinded) => {
  channel.publish('isBlinded', {
    isBlinded: !!isBlinded,
  })
}

const publishIsMutedAbly = (channel, isMuted) => {
  channel.publish('isMuted', {
    isMuted: !!isMuted,
  })
}

export { publishShareScreenAbly, publishIsBlindedAbly, publishIsMutedAbly }
