import React, { useState, useContext, useEffect } from 'react'
import get from 'lodash/get'

// eslint-disable-next-line no-unused-vars
import { GET_APPOINTMENT, GET_ROOM_TOKEN, GET_USER_SESSION } from 'App/Queries'
import { ATTEND_APPOINTMENT } from 'App/Mutators'

import { Spin } from 'antd'
import Room from './Components/Room'

import { AblyContext } from 'App/Providers'
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks'

const AppointmentScreen = ({ match, history }) => {
  const [username, setUsername] = useState(null)
  const [roomName, setRoomName] = useState(null)
  const [userId, setUserId] = useState(null)
  const [token, setToken] = useState(null)
  const [loading, setLoading] = useState(true)
  const [patient, setPatient] = useState(null)

  const ablyState = useContext(AblyContext)

  const [getUserSession, sessionQueryResult] = useLazyQuery(GET_USER_SESSION)

  useEffect(() => {
    if (Object.keys(ablyState.userSession).length > 0) {
      const { firstname, lastname } = ablyState.userSession
      const userName = `${firstname} ${lastname}`
      setUsername(userName)
      setUserId(ablyState.userSession._id)
    } else {
      getUserSession()

      if (sessionQueryResult.data) {
        const { firstname, lastname, _id } = get(
          sessionQueryResult.data,
          'session',
        )
        const userName = `${firstname} ${lastname}`
        setUsername(userName)
        setUserId(_id)
      }
    }
  }, [ablyState.userSession, getUserSession, sessionQueryResult.data])

  const [attendAppointmentQuery] = useMutation(ATTEND_APPOINTMENT, {
    variables: { id: match.params._id },
  })

  const GET_APPOINTMENT_QUERY = useQuery(GET_APPOINTMENT, {
    variables: { id: match.params._id },
  })

  const appointment = get(GET_APPOINTMENT_QUERY.data, 'appointment', [])

  const [getRoomToken, GET_ROOM_TOKEN_QUERY] = useLazyQuery(GET_ROOM_TOKEN, {
    variables: { username, room: roomName },
  })

  useEffect(() => {
    setToken(match.params._id)
  }, [match.params._id])

  useEffect(() => {
    if (Object.keys(appointment).length > 0) {
      setPatient(appointment.patient)
      setRoomName(appointment.room)
    }

    if (Object.keys(appointment).length > 0 && appointment.attendedAt === null)
      attendAppointmentQuery()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment])

  useEffect(() => {
    if (username !== null && roomName !== null) {
      getRoomToken()
      setToken(get(GET_ROOM_TOKEN_QUERY.data, 'roomToken'))
      setLoading(false)
    }
  }, [GET_ROOM_TOKEN_QUERY.data, getRoomToken, roomName, username])

  if (loading || patient === null)
    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Spin size="large" />
      </div>
    )
  return (
    <>
      {appointment && (
        <Room
          appointment={appointment}
          appointmentId={match.params._id}
          history={history}
          patient={patient}
          roomName={roomName}
          token={token}
          userId={userId}
          username={username}
        />
      )}
    </>
  )
}

export default AppointmentScreen
