import { createLocalAudioTrack } from 'twilio-video'
import * as Sentry from '@sentry/browser'

const checkMuted = async (room, isMuted) => {
  if (room)
    if (isMuted) {
      await room.localParticipant.audioTracks.forEach(async ({ track }) => {
        await track.disable()
      })
    } else
      await room.localParticipant.audioTracks.forEach(
        async ({ track }) => await track.enable(),
      )
}

const replaceAudio = async (room, isMuted) => {
  const list = await navigator.mediaDevices.enumerateDevices()
  if (room) {
    const tracks = []
    await room.localParticipant.tracks.forEach(({ track }) => {
      if (track.kind === 'audio') tracks.push(track)
    })
    tracks.forEach(async track => await track.stop())
    await room.localParticipant.unpublishTracks(tracks)
    tracks.forEach(async track => await track.detach())
    const { localParticipant } = room
    for (const device of list) {
      if (device.kind === 'audioinput') {
        await createLocalAudioTrack({
          deviceId: device.deviceId,
        })
          .then(async localAudioTrack => {
            try {
              await localParticipant
                .publishTrack(localAudioTrack)
                .then(async () => await checkMuted(room, isMuted))
            } catch (e) {
              Sentry.captureMessage('Error publish audiotrack')
              Sentry.captureException(e)
            }
          })
          .catch(e => {
            Sentry.captureMessage('Error function replaceAudio')
            Sentry.captureException(e)
          })
        break
      }
    }
  }
}

export { replaceAudio }
