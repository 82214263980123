import React, { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import { useQuery } from 'react-apollo'
import { GET_USER_SESSION } from '../../Queries'
import { useLocation } from 'react-router-dom'

const IntercomApp = ({ children }) => {
  const location = useLocation()
  const [payload, setPayload] = useState(null)
  const { data } = useQuery(GET_USER_SESSION, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (data && Auth)
      setPayload({
        app_id: 'd6637657',
        type: 'doctor',
        user_id: data.session._id,
        name: `${data.session.firstname} ${data.session.lastname}`,
        email: Auth.user.attributes.email,
      })
  }, [data])
  const shutdown = () => {
    window.Intercom('shutdown')
  }

  const boot = payload => {
    window.Intercom(
      'boot',
      payload || {
        app_id: 'd6637657',
        type: 'doctor',
      },
    )
  }

  useEffect(() => {
    Auth.currentSession()
      .then(() => {
        shutdown()
        boot(payload)
      })
      .catch(() => {
        shutdown()
        boot()
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, payload])

  return <>{children}</>
}

export default IntercomApp
