import ApolloClient from 'apollo-boost'
import {
  getAuthToken
} from '../Helpers'

const Client =
  new ApolloClient({
    // eventualmente puede venir un token
    uri:
      process.env.REACT_APP_URL_ENDPOINT ||
      'https://9jgy8nlxth.execute-api.us-east-1.amazonaws.com/development/graphql',
    request: async operation => {
      const Authorization = await getAuthToken()
      operation.setContext({
        headers: Authorization
      })
    }
  })


export { Client }
