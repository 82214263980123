import React, { useContext } from 'react'

import { useGetUploads, useGetUploadFetchUrl } from './Services'

import { CallFilesContext } from 'App/Screens/AppointmentScreen/Components/Components/CallFiles/Provider'

import styles from './files.module.css'

import { Spin, Typography } from 'antd'
import { PaperClipOutlined } from '@ant-design/icons'

const File = ({ item }) => {
  const { data, loading, error } = useGetUploadFetchUrl(item.key)
  const filename = item.key.split('/')[2]

  if (loading) return <Spin />
  if (error) return 'Error cargando archivo.'

  return (
    <div style={{ display: 'flex' }}>
      <PaperClipOutlined
        style={{ color: '#E5E5E5', marginTop: 5, marginRight: 5 }}
      />
      <a
        href={data.uploadFetchUrl}
        target="_blank"
        rel="noopener noreferrer"
        style={{ paddingRight: 10 }}>
        <Typography className={styles.filename}>{filename}</Typography>
      </a>
    </div>
  )
}

const Files = ({ room }) => {
  const { setFiles } = useContext(CallFilesContext)
  const { processingFile } = useContext(CallFilesContext)

  const { data, loading, error } = useGetUploads(room)

  if (loading || processingFile) return <Spin />
  if (error) return 'Error cargando archivos.'

  if (data)
    setFiles(data.uploads)


  return data.uploads.map((item, key) => (
    // eslint-disable-next-line react/no-array-index-key
    <File key={key} item={item} room={room} />
  ))
}

export default Files
