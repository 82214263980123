import * as React from 'react'

function MicIconOff(props) {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={props.width}
      height={props.height}
      fill={props.fill}
      viewBox="0 0 475.092 475.092"
      xmlSpace="preserve"
      {...props}>
      <g>
        <g>
          <path
            d="M113.922,269.803c-2.856-11.419-4.283-22.172-4.283-32.26v-36.55c0-4.947-1.809-9.229-5.424-12.847
			c-3.617-3.616-7.898-5.424-12.847-5.424c-4.952,0-9.235,1.809-12.851,5.424c-3.617,3.617-5.426,7.9-5.426,12.847v36.547
			c0,21.129,3.999,41.494,11.993,61.106L113.922,269.803z"
          />
          <path
            d="M237.545,328.897c25.126,0,46.638-8.946,64.521-26.83c17.891-17.884,26.837-39.399,26.837-64.525v-36.547L431.972,97.929
			c1.902-1.903,2.854-4.093,2.854-6.567c0-2.474-0.952-4.664-2.854-6.567l-23.407-23.413c-1.91-1.906-4.097-2.856-6.57-2.856
			c-2.472,0-4.661,0.95-6.564,2.856L43.117,413.698c-1.903,1.902-2.852,4.093-2.852,6.563c0,2.478,0.949,4.668,2.852,6.57
			l23.411,23.411c1.904,1.903,4.095,2.851,6.567,2.851c2.475,0,4.665-0.947,6.567-2.851l72.519-72.519
			c20.933,12.949,43.299,20.656,67.093,23.127v37.691h-73.089c-4.949,0-9.235,1.811-12.847,5.428
			c-3.618,3.613-5.43,7.898-5.43,12.847c0,4.941,1.812,9.233,5.43,12.847c3.612,3.614,7.898,5.428,12.847,5.428h182.718
			c4.948,0,9.232-1.813,12.847-5.428c3.62-3.613,5.428-7.905,5.428-12.847c0-4.948-1.808-9.233-5.428-12.847
			c-3.614-3.617-7.898-5.428-12.847-5.428h-73.087v-37.691c41.302-4.565,75.988-22.408,104.067-53.526
			c28.072-31.117,42.11-67.711,42.11-109.776v-36.554c0-4.947-1.808-9.229-5.421-12.845c-3.621-3.617-7.902-5.426-12.851-5.426
			c-4.945,0-9.229,1.809-12.847,5.426c-3.617,3.616-5.424,7.898-5.424,12.845v36.547c0,35.214-12.519,65.333-37.545,90.359
			s-55.151,37.544-90.362,37.544c-20.557,0-40.065-4.849-58.529-14.561l27.408-27.401
			C216.707,327.097,227.079,328.897,237.545,328.897z"
          />
          <path
            d="M290.223,16.849C274.518,5.618,256.959,0,237.545,0c-25.125,0-46.635,8.951-64.524,26.84
			c-17.89,17.89-26.835,39.399-26.835,64.525v146.177L323.483,60.244C317.008,42.543,305.927,28.077,290.223,16.849z"
          />
        </g>
      </g>
    </svg>
  )
}

export default MicIconOff
