import React from 'react'
import styles from '../../AppointmentScreenStyles.module.css'
import MicIconOff from 'App/Assets/SVG/MicIconOff'
import CameraIconOff from 'App/Assets/SVG/CameraIconOff'

const AlertOfAudioAndVideo = ({
  messagePatientMuted,
  messageDoctorBlinded,
}) => {
  return (
    <>
      {(messagePatientMuted || messageDoctorBlinded) && (
        <>
          {messageDoctorBlinded && (
            <div className={styles.doctorBlindedContainer} />
          )}
          <div className={styles.doctorAlertContainer}>
            <div style={{ display: 'flex' }}>
              {messagePatientMuted && (
                <div className={styles.doctorCircleAlert}>
                  <MicIconOff width="25px" height="25px" fill="#ec3832" />
                </div>
              )}
              {messageDoctorBlinded && (
                <div className={styles.doctorCircleAlert}>
                  <CameraIconOff width="25px" height="25px" fill="#ec3832" />
                </div>
              )}
            </div>
            <div style={{ marginTop: '8px', fontWeight: 'normal' }}>
              {messagePatientMuted && (
                <div>Paciente ha desactivado el micrófono.</div>
              )}
              {messageDoctorBlinded && (
                <div>Paciente ha desactivado la cámara.</div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default AlertOfAudioAndVideo
