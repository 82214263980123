import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useContext,
} from 'react'
import { Row, Col, Modal } from 'antd'
import {
  connect,
  createLocalTracks,
  createLocalVideoTrack,
  LocalVideoTrack,
} from 'twilio-video'
import get from 'lodash/get'

import Participant from './Components/Participant'
import ChatApp from './Components/ChatApp'
import CallCard from './Components/CallCard'
import OwnVideo from './Components/OwnVideo'
import VideoControls from './Components/VideoControls'
import Header from './Components/Header'
import { replaceAudio } from './Helpers/VideoManagement'
import ErrorTestModal from './Helpers/ErrorTestModal'
import AlertOfAudioAndVideo from './Components/AlertOfAudioAndVideo'
import AppointmentSurvey from './Components/AppointmentSurvey/Survey'
import styles from '../AppointmentScreenStyles.module.css'
import sound from 'App/Assets/Sounds/pling.mp3'
import leavePresenceChannel from 'App/Helpers/leavePresenceChannel'
import {
  publishIsBlindedAbly,
  publishIsMutedAbly,
  publishShareScreenAbly,
} from './Helpers/PublishsAbly'
import {
  subscribeIsMutedAbly,
  subscribeIsBlindedAbly,
} from './Helpers/SubscribesAbly'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { GET_USER_SESSION } from 'App/Queries'
import { CLOSE_APPOINTMENT } from 'App/Mutators'

import { AblyContext } from 'App/Providers'
import * as Sentry from '@sentry/browser'

const Room = ({
  appointment,
  appointmentId,
  history,
  patient,
  roomName,
  token,
  username,
}) => {
  const [modalError, setModalError] = useState(false)
  const [customMessage, setCustomMessage] = useState(null)
  const [streamShareScreen, setStreamShareScreen] = useState('')
  const [isShowFiles, setIsShowFiles] = useState(false)
  const [isMuted, setIsMuted] = useState(false)
  const [isBlinded, setIsBlinded] = useState(false)
  const [isShareScreen, setIsShareScreen] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [cameraNumber, setCameraNumber] = useState(1)
  const [controlsVisible, setControlsVisible] = useState(true)
  const [room, setRoom] = useState(null)
  const [showCloseModal, setShowCloseModal] = useState(false)
  const [participants, setParticipants] = useState([])
  const [newMessage, setNewMessage] = useState(false)
  const [allVideoDevices, setAllVideoDevices] = useState([])
  const [videoOnChangeDevice, setVideoOnChangeDevice] = useState('')
  const [isPatientOnline, setIsPatientOnline] = useState('')
  const [messagePatientMuted, setMessagePatientMuted] = useState(false)
  const [messageDoctorBlinded, setMessageDoctorBlinded] = useState(false)
  const [disconnected, setDisconnected] = useState(false)
  const [doctorAcceptSharingScreen, setDoctorAcceptSharingScreen] = useState(
    false,
  )
  const [showDoctorOpinion, setShowDoctorOpinion] = useState(false)

  const ablyState = useContext(AblyContext)
  const { realtime, stateConnection, userSession } = ablyState

  const timerRef = useRef()

  useEffect(() => {
    if (showDoctorOpinion) setShowCloseModal(false)
  }, [showDoctorOpinion])

  const cleanUpAbly = useCallback(() => {
    if (!realtime || !roomName || !appointmentId) return
    leavePresenceChannel(realtime, `appointment:${roomName}`)
    leavePresenceChannel(realtime, 'appointment')
    leavePresenceChannel(
      realtime,
      `queue_reactor_professional_professionalId_${appointmentId}_${roomName}`,
    )
  }, [realtime, roomName, appointmentId])

  const exitCall = useCallback(() => {
    history.push('/lobby')
  }, [history])

  // redirect doctor to the lobby if the appointment is closed and review was made
  useEffect(() => {
    if (appointment.review !== null) {
      cleanUpAbly()
      exitCall()
    }
  }, [appointment, cleanUpAbly, exitCall])

  // redirect doctor to the survey component if review is null
  useEffect(() => {
    if (appointment.review === null && appointment.closedAt)
      setShowDoctorOpinion(true)
  }, [appointment.closedAt, appointment.review])

  const cleanUpVideo = useCallback(async () => {
    if (!room) return
    const tracks = []
    await room.localParticipant.tracks.forEach(({ track }) =>
      tracks.push(track),
    )
    tracks.forEach(async track => await track.stop())
    await room.localParticipant.unpublishTracks(tracks)
    tracks.forEach(async track => await track.detach())
  }, [room])

  const cleanUpOnlyVideo = useCallback(async () => {
    if (!room) return
    const tracks = []
    await room.localParticipant.tracks.forEach(({ track }) => {
      if (track.kind === 'video') tracks.push(track)
    })
    tracks.forEach(async track => await track.stop())
    await room.localParticipant.unpublishTracks(tracks)
    tracks.forEach(async track => await track.detach())
  }, [room])

  useEffect(() => {
    if (ablyState) ablyState.setAppointmentRoomName(roomName)
  }, [ablyState, roomName])

  const sessionQueryResult = useQuery(GET_USER_SESSION)
  const [
    closeAppointment,
    {
      data: dataClosedMutation,
      loading: loadingClosedMutation,
      error: errorClosedMutation,
    },
  ] = useMutation(CLOSE_APPOINTMENT)

  useEffect(() => {
    const sessionFunction = () => {
      if (Object.keys(ablyState.userSession).length === 0)
        if (sessionQueryResult.data) {
          const sessions = get(sessionQueryResult.data, 'session')

          if (sessions) ablyState.setUserSession(sessions)
          if (ablyState.connectionId === null)
            ablyState.setConnectionId(sessions._id)
        }
    }

    sessionFunction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ablyState.userSession, sessionQueryResult])
  /* #endregion */

  const handleShowFiles = () => {
    setIsShowFiles(!isShowFiles)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleMute = useCallback(async () => {
    if (room)
      await room.localParticipant.audioTracks.forEach(async ({ track }) =>
        isMuted ? await track.enable() : await track.disable(),
      )
    setIsMuted(!isMuted)
  })

  const handleBlind = async () => {
    if (room)
      await room.localParticipant.videoTracks.forEach(async ({ track }) =>
        isBlinded ? await track.enable() : await track.disable(),
      )
    setIsBlinded(!isBlinded)
  }

  const checkBlinded = async () => {
    if (room)
      if (isBlinded) {
        await room.localParticipant.videoTracks.forEach(async ({ track }) => {
          await track.disable()
        })
      } else
        await room.localParticipant.videoTracks.forEach(
          async ({ track }) => await track.enable(),
        )
  }

  // Navigator handler of audioChange
  useEffect(() => {
    navigator.mediaDevices.ondevicechange = replaceAudio(room, isMuted)
  }, [room, isMuted])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateVideoDevice = async media => {
    cleanUpOnlyVideo()
    if (room) {
      const { localParticipant } = room
      await createLocalVideoTrack({
        deviceId: media,
      }).then(async localVideoTrack => {
        try {
          await localParticipant.publishTrack(localVideoTrack).then(() => {
            checkBlinded()
          })
          setVideoOnChangeDevice(localVideoTrack)
        } catch (e) {
          Sentry.captureMessage('Error function updateVideoDevice')
          Sentry.captureException(e)
        }
      })
    }
  }

  const shareScreen = useCallback(
    async isShare => {
      if (room) {
        await cleanUpOnlyVideo()
        if (isShare)
          try {
            const stream = await navigator.mediaDevices.getDisplayMedia({
              video: true,
            })
            setStreamShareScreen(stream)

            const screenTrack = await new LocalVideoTrack(stream.getTracks()[0])

            if (screenTrack) {
              room.localParticipant.publishTrack(screenTrack)
              setVideoOnChangeDevice(screenTrack)
              setDoctorAcceptSharingScreen(true)
            }
          } catch (e) {
            updateVideoDevice(allVideoDevices[cameraNumber - 1].deviceId)
            setIsShareScreen(false)
            setDoctorAcceptSharingScreen(false)
          }
        else updateVideoDevice(allVideoDevices[cameraNumber - 1].deviceId)
      }
    },
    [allVideoDevices, cameraNumber, cleanUpOnlyVideo, room, updateVideoDevice],
  )

  const handleHoverOver = () => {
    clearTimeout(timerRef.current)
    setControlsVisible(true)
  }

  const handleHoverOut = () => {
    clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => setControlsVisible(false), 500)
  }

  const handleShowChat = () => {
    setShowChat(!showChat)
  }
  const handleShareScreen = () => {
    setIsShareScreen(!isShareScreen)
    shareScreen(!isShareScreen)
  }

  const handleNewMessage = async option => {
    setNewMessage(option)
    if (option) {
      const audio = await new Audio(sound)
      const promise = await audio.play()
      if (promise !== undefined)
        await promise.then(() => {}).catch(error => console.error)
      else await audio.play()
    }
  }

  // useEffect(() => {
  //   history.listen((location, action) => {
  //     if (action === 'POP')
  //       ablyState
  //         .leavePresenceChannel([`appointment:${roomName}`, 'appointment'])
  //         .then(() => {
  //           history.push('/lobby')
  //         })
  //   })
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [ablyState.userSession._id, history, realtime, roomName])

  const gotDevices = mediaDevices => {
    mediaDevices.map(media => {
      if (media.kind === 'videoinput')
        setAllVideoDevices(allVideoDevices => [...allVideoDevices, media])
      return true
    })
  }

  const handleJumpingCameras = index => {
    updateVideoDevice(allVideoDevices[index - 1].deviceId)
  }

  const handleCameraNumber = number => {
    setCameraNumber(number)
  }

  const handleCloseAppointment = async () => {
    if (!loadingClosedMutation) {
      cleanUpVideo()
      closeAppointment({ variables: { id: appointmentId } })
    }
  }

  if (errorClosedMutation) console.log('Error finalizando cita')

  if (loadingClosedMutation | dataClosedMutation) {
    const channelName = `appointment:${roomName}`
    const channel = realtime.channels.get(channelName)
    channel.attach(err => {
      if (err) console.err('Could not attach to channel')
      if (!showDoctorOpinion) {
        channel.publish('done', 'leaving room')
        setShowDoctorOpinion(true)
        ablyState.leavePresenceChannel([channelName])
      }
    })
  }

  useEffect(() => {
    if (streamShareScreen)
      streamShareScreen.getVideoTracks()[0].onended = () => {
        updateVideoDevice(allVideoDevices[0].deviceId)
        setIsShareScreen(!isShareScreen)
      }
  }, [allVideoDevices, isShareScreen, streamShareScreen, updateVideoDevice])

  useEffect(() => {
    if (!roomName || !token) return

    if (stateConnection === 'connected') {
      const participantConnected = participant => {
        setParticipants(prevParticipants => [...prevParticipants, participant])
      }
      const participantDisconnected = participant => {
        setParticipants(prevParticipants =>
          prevParticipants.filter(p => p !== participant),
        )
      }
      navigator.mediaDevices.enumerateDevices().then(gotDevices)

      const initializeVideo = async () => {
        const tracks = await createLocalTracks().catch(e => {
          setModalError(true)
          setCustomMessage(
            'Tenemos problemas al acceder a su cámara y/o micrófono.',
          )
        })

        if (tracks)
          await connect(token, {
            name: roomName,
            tracks,
          })
            .then(room => {
              setRoom(room)
              room.on('participantConnected', participantConnected)
              room.on('participantDisconnected', participantDisconnected)
              room.participants.forEach(participantConnected)
              try {
                room.localParticipant.videoTracks.forEach(track => {
                  setVideoOnChangeDevice(track.track)
                })
              } catch (e) {
                Sentry.captureMessage('Error LocalParticipant.videoTracks')
                Sentry.captureException(e)
              }
            })
            .catch(e => {
              Sentry.captureMessage('Error video.Connect')
              Sentry.captureException(e)
            })
      }

      initializeVideo()

      return () => {
        setRoom(currentRoom => {
          if (
            currentRoom &&
            currentRoom.localParticipant.state === 'connected'
          ) {
            currentRoom.localParticipant.tracks.forEach(trackPublication => {
              trackPublication.track.stop()
            })
            currentRoom.disconnect()
            return null
          } else return currentRoom
        })
      }
    }

    if (stateConnection === 'disconnected' || stateConnection === 'suspended') {
      setParticipants([])
      setAllVideoDevices([])
      setVideoOnChangeDevice(null)
    }
  }, [roomName, stateConnection, token])

  useEffect(() => {
    if (stateConnection === 'connected' && room && disconnected) {
      if (isMuted) setIsMuted(false)
      if (isBlinded) setIsBlinded(false)
      if (showChat) setShowChat(false)
      if (cameraNumber > 1) setCameraNumber(1)
      if (isShareScreen) {
        setIsShareScreen(false)
        setDoctorAcceptSharingScreen(false)
      }
      setDisconnected(false)
    }

    if (stateConnection === 'disconnected' || stateConnection === 'suspended')
      setDisconnected(true)
  }, [
    cameraNumber,
    disconnected,
    isBlinded,
    isMuted,
    isShareScreen,
    room,
    showChat,
    stateConnection,
  ])

  // useEffect(() => {
  //   console.log()
  //   ablyState &&
  //     ablyState.setEnterChannels([
  //       {
  //         channelName: `queue_testing_professional_professionalId_${appointmentId}_${roomName}`,
  //       },
  //     ])
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  useEffect(() => {
    if (!realtime || !roomName || ablyState.userSession === null) return
    ablyState.setEnterChannels([
      {
        channelName: `appointment:${roomName}`,
      },
      {
        channelName: `appointment`,
      },
      {
        channelName: `wait-room:${ablyState.userSession._id}`,
      },
      {
        channelName: `queue_reactor_professional_professionalId_${appointmentId}_${roomName}`,
      },
    ])

    return () => {
      if (realtime && roomName && appointmentId) cleanUpAbly()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realtime, roomName, cleanUpAbly])

  useEffect(() => {
    if (
      ablyState.channels &&
      ablyState.userSession !== undefined &&
      ablyState.waitRoomId !== null
    ) {
      const channel =
        ablyState.channels[`wait-room:${ablyState.userSession._id}`]
      if (ablyState.waitRoomId.length > 1 && channel)
        channel.connection.publish('inAppointmentMessage', {
          inAppointment: true,
        })
    }
  }, [ablyState])

  useEffect(() => {
    return () => cleanUpVideo()
  }, [cleanUpVideo])

  useEffect(() => {
    if (roomName !== null && ablyState.appointmentRoomId !== null) {
      const isPatientOnline = ablyState.appointmentRoomId.find(
        p => p.clientId === patient._id,
      )
      setIsPatientOnline(isPatientOnline)
    }
  }, [ablyState.appointmentRoomId, patient, roomName])

  useEffect(() => {
    if (!isShareScreen) setDoctorAcceptSharingScreen(false)
  }, [isShareScreen])

  useEffect(() => {
    if (roomName !== null && ablyState.appointmentRoomId !== null)
      if (ablyState.appointmentRoomId.length > 1) {
        const channel = ablyState.channels[`appointment:${roomName}`]
        if (channel && channel.connection) {
          publishShareScreenAbly(
            channel.connection,
            doctorAcceptSharingScreen,
            isShareScreen,
          )
          publishIsMutedAbly(channel.connection, isMuted)
          publishIsBlindedAbly(channel.connection, isBlinded)
        }
      }
  }, [
    ablyState.appointmentRoomId,
    ablyState.channels,
    doctorAcceptSharingScreen,
    isBlinded,
    isMuted,
    isShareScreen,
    roomName,
  ])

  useEffect(() => {
    if (roomName !== null && ablyState.appointmentRoomId !== null)
      if (ablyState.appointmentRoomId.length > 1) {
        const channel = ablyState.channels[`appointment:${roomName}`]
        if (channel && channel.connection) {
          subscribeIsMutedAbly(channel.connection, setMessagePatientMuted)
          subscribeIsBlindedAbly(channel.connection, setMessageDoctorBlinded)
        }
      }
  }, [ablyState.appointmentRoomId, ablyState.channels, roomName])

  return (
    <div className={styles.container}>
      <Header
        endCall={() => setShowCloseModal(true)}
        isTimerVisible={!showDoctorOpinion}
        username={username}
      />
      <Modal
        title={customMessage}
        visible={modalError}
        onCancel={() => {
          setModalError(false)
        }}
        footer={null}>
        <ErrorTestModal customMessage={customMessage} isMobile={false} />
      </Modal>

      {!showDoctorOpinion ? (
        <Row
          style={{
            height: '90%',
            width: '100%',
            textAlign: 'center',
            overflowY: 'hidden',
          }}
          className={styles.appointmentContainer}>
          <Col span={24} style={{ height: '100%', overflowX: 'hidden' }}>
            <div
              className={styles.video}
              onMouseOver={handleHoverOver}
              onMouseOut={handleHoverOut}>
              {participants && participants.length > 0 ? (
                <Participant
                  key={participants[0].sid}
                  participant={participants[0]}
                  isOnline={isPatientOnline}
                />
              ) : stateConnection === 'disconnected' ||
                stateConnection === 'suspended' ? (
                <div className={styles.participant}>
                  <div
                    style={{
                      position: 'relative',
                      top: '30%',
                    }}>
                    <p className={styles.allReady}>¡Problemas de Conexión!</p>
                    <p className={styles.waitingDoctor}>
                      Estamos intentando reconectarnos...
                    </p>
                    <p>Por favor, revisa tu conexión.</p>
                  </div>
                </div>
              ) : (
                stateConnection === 'connected' && (
                  <div className={styles.participant}>
                    <div
                      style={{
                        position: 'relative',
                        top: '30%',
                      }}>
                      <p className={styles.allReady}>¡Todo listo!</p>
                      <p className={styles.waitingDoctor}>
                        Esperando que tu paciente se conecte a la sala...
                      </p>
                      <p>
                        Por el momento, no se encuentra más nadie conectado.
                      </p>
                    </div>
                  </div>
                )
              )}
              <CallCard
                patient={patient}
                isConnected={isPatientOnline}
                room={roomName}
                handleShowFiles={handleShowFiles}
                isShowFiles={isShowFiles}
              />
              <AlertOfAudioAndVideo
                messagePatientMuted={messagePatientMuted}
                messageDoctorBlinded={messageDoctorBlinded}
              />
              {room && (
                <OwnVideo
                  participant={room.localParticipant}
                  isShareScreen={isShareScreen}
                  video={videoOnChangeDevice}
                  videoDevicesLength={allVideoDevices.length}
                  videoDevices={allVideoDevices}
                  cameraNumber={room && cameraNumber}
                  handleJumpingCamera={room && handleJumpingCameras}
                  handleCameraNumber={room && handleCameraNumber}
                  doctorAcceptSharingScreen={doctorAcceptSharingScreen}
                />
              )}

              <VideoControls
                isBlinded={isBlinded}
                isMuted={isMuted}
                isChat={showChat}
                isShareScreen={isShareScreen}
                newMessage={newMessage}
                videoDevicesLength={allVideoDevices.length}
                cameraNumber={cameraNumber}
                handleMute={handleMute}
                handleBlind={handleBlind}
                handleShowChat={handleShowChat}
                handleShareScreen={handleShareScreen}
                isVisible={controlsVisible}
                handleShowFiles={handleShowFiles}
              />
              {room && roomName && token ? (
                <ChatApp
                  username={room.localParticipant.identity}
                  patient={patient}
                  channelName={roomName}
                  token={token}
                  handleShowChat={handleShowChat}
                  handleNewMessage={handleNewMessage}
                  showChat={showChat}
                />
              ) : (
                ''
              )}
            </div>
          </Col>
        </Row>
      ) : (
        <AppointmentSurvey
          doctorSession={userSession}
          appointmentId={appointmentId}
          exitCall={() => exitCall()}
        />
      )}

      <Modal
        centered
        visible={showCloseModal}
        onOk={handleCloseAppointment}
        onCancel={() => setShowCloseModal(false)}
        cancelText="Cancelar"
        okText={loadingClosedMutation ? 'Cargando...' : 'Finalizar'}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h2>¿Está seguro que quiere finalizar la llamada?</h2>
        </div>
      </Modal>
    </div>
  )
}

export default Room
