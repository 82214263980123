import React from 'react'
import { Checkbox } from 'antd'

const StepOneContent = ({ stepOneOpinions, stepOneOptions, handleStepOneOptions }) => {
  return (

    <Checkbox.Group
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: '3vh 0vh',
      }}
      defaultValue={stepOneOpinions}
      options={stepOneOptions}
      onChange={handleStepOneOptions}
    />
  )
}

export default StepOneContent