import React, { useState, useEffect, useCallback, useReducer, useContext } from 'react'
import { List } from 'antd'
import dayjs from 'dayjs'
import styles from '../../../LobbyScreenStyles.module.css'

import LobbyScreenContext from '../../Context/LobbyScreenContext'

import {
    PatientFiles,
    Item
} from '../Components'

const switchAppointments = (state, action) => {
    switch (action.type) {
        case 'UPDATE_APPOINTMENTS':
            return {
                appointments: action.appointments
            }

        default:
            return {
                appointments: action.appointments
            }
    }
}

const ConsultasComponent = ({
    appointments,
    loading,
    handleClick,
    hours,
    session,
    entered,
    history,
    ablyState,
    setAppInfoStatus
}) => {

    const [showFiles, setShowFiles] = useState(false)
    const [appointment, setAppointment] = useState([])
    const [loadingFiles, setLoadingFiles] = useState(false)
    const [statusAppointments, setStatusAppointments] = useState([])

    const initialAppointmentsState = {
        appointments: []
    }

    const [stateAppointments, dispatchAppointments] = useReducer(
        switchAppointments,
        initialAppointmentsState,
    )

    const { compressedMode } = useContext(LobbyScreenContext)

    useEffect(() => {
        dispatchAppointments({
            type: 'UPDATE_APPOINTMENTS',
            appointments
        })
    }, [appointments])

    useEffect(() => {
        if (compressedMode) {
            const compressedAppointments = stateAppointments.appointments.filter(obj => obj.hasOwnProperty('appointment'))
            dispatchAppointments({
                type: 'UPDATE_APPOINTMENTS',
                appointments: compressedAppointments
            })
        } else

            dispatchAppointments({
                type: 'UPDATE_APPOINTMENTS',
                appointments: statusAppointments
            })


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [compressedMode])

    const prepareAppointments = async () => {

        try {
            const rows = []
            for (const hour of hours) {
                const row = {
                    time: hour.format('HH:mm'),
                }

                const appointment = statusAppointments.find(el => {
                    const sameHour = hour.hour() === el.hour
                    const sameMinute = hour.minute() === el.minute
                    return sameHour && sameMinute
                })

                if (!appointment) {
                    if (hour.minute() % 30 === 0) rows.push(row)
                    continue
                }

                rows.push({
                    ...row,
                    appointment,
                })
            }

            dispatchAppointments({
                type: 'UPDATE_APPOINTMENTS',
                appointments: rows
            })
        } catch (err) {
            console.error(err)
        }
    }

    useEffect(() => {
        const statusAppointments = appointments.map(e => ({
            ...e,
            statusConnection: 'disconnected',
        }))
        setStatusAppointments(statusAppointments)
    }, [appointments])

    useEffect(() => {
        prepareAppointments()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusAppointments])

    useEffect(() => {
        if (!compressedMode)
            prepareAppointments()
        else {
            const compressedAppointments = stateAppointments.appointments.filter(obj => obj.hasOwnProperty('appointment'))
            dispatchAppointments({
                type: 'UPDATE_APPOINTMENTS',
                appointments: compressedAppointments
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [compressedMode])

    const ShowFilesModal = () => {
        setShowFiles(!showFiles)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fillFiles = useCallback(index => {
        if (index === null) return
        setLoadingFiles(true)
        const copy = stateAppointments.appointments[index].appointment
        setAppointment(copy)
    })

    const actualHour = dayjs()
    const startDay = dayjs()
        .hour(8)
        .minute(0)
        .second(0)
    const limit = Math.ceil(actualHour.diff(startDay, 'minutes') / 5) - 1

    const resetCounter = () => ablyState.setCounter(0)

    if (appointments)
        return (
            <div
                className={styles.fatherPatientListHeight}>
                <List
                    className={styles.patientList}
                    size={'small'}
                    loading={loading}
                    dataSource={stateAppointments.appointments}
                    renderItem={(item, index) => (
                        <Item cita={item}
                            index={index}
                            styles={styles}
                            setShowFiles={setShowFiles}
                            fillFiles={fillFiles}
                            limit={limit}
                            handleClick={handleClick}
                            ablyState={ablyState}
                            setAppInfoStatus={setAppInfoStatus}
                        />)
                    } />
                <PatientFiles
                    showFiles={showFiles}
                    ShowFilesModal={ShowFilesModal}
                    appointment={appointment}
                    loadingFiles={loadingFiles}
                    setLoadingFiles={setLoadingFiles}
                    ablyState={ablyState}
                    resetCounter={resetCounter}
                />
            </div>
        )
    else
        return ('Cargando')

}

export default ConsultasComponent
