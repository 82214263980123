import React from 'react'
import { Checkbox } from 'antd'

const StepThreeContent = ({ stepThreeOpinions, stepThreeOptions, handleStepThreeOptions }) => {
  return (

    <Checkbox.Group
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: '3vh 0vh',
      }}
      defaultValue={stepThreeOpinions}
      options={stepThreeOptions}
      onChange={handleStepThreeOptions}
    />
  )
}

export default StepThreeContent