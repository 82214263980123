export const stepOneOptions = [
  'El audio se cortaba',
  'Había eco',
  'Los demás no me oian',
  'No oía a los demás',
  'El sonido llegaba con retraso',
  'Otro problema de audio',
]

export const stepTwoOptions = [
  'No veía el video de los demás',
  'El audio y el video no estaban sincronizados',
  'Mi cámara no funcionaba',
  'El video se veía borroso',
  'El video se cortaba',
  'Otro problema de video',
]

export const stepThreeOptions = [
  'No veía la presentación',
  'La presentación se actualizaba con lentitud',
  'No podia mostrar contenido',
  'La presentación se veía borrosa',
  'Otro problema con la presentación',
]
