import { gql } from 'apollo-boost'

const GET_UPLOADS = gql`
  query($room: String!) {
    uploads(room: $room) {
      size
      key
    }
  }
`

const GET_APPOINTMENTS = gql`
  {
    todayAppointments {
      _id
      token
      patient {
        _id
        rut
        passport
        firstname
        lastname
        email
        phone
      }
      room
      year
      month
      day
      hour
      minute
      closedAt
    }
  }
`

const GET_CONSULTATIONS = gql`
  {
    todayConsultations {
      _id
      device {
        _id
        name
        location
      }
      patient {
        _id
        rut
        passport
        firstname
        lastname
        birthdate
        email
        phone
      }
      professionals {
        from {
          _id
          firstname
          lastname
          code
        }
        to {
          _id
          firstname
          lastname
          code
        }
      }
      year
      month
      day
      hour
      minute
      room
      description
      attachments
      status
      scheduledAt
      attendedAt
      closedAt
      createdAt
      updatedAt
    }
  }
`

const GET_UPLOAD_FETCH_URL = gql`
  query($key: String!) {
    uploadFetchUrl(key: $key)
  }
`

const GET_USER_SESSION = gql`
  {
    session {
      _id
      firstname
      lastname
    }
  }
`

export {
  GET_UPLOADS,
  GET_APPOINTMENTS,
  GET_UPLOAD_FETCH_URL,
  GET_USER_SESSION,
  GET_CONSULTATIONS,
}
