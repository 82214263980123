import { gql } from 'apollo-boost'

const ATTEND_APPOINTMENT = gql`
mutation attendAppointment($id: ID!) {
    attendAppointment(_id: $id) {
      _id
    }
  }
`

const CLOSE_APPOINTMENT = gql`mutation closeAppointment($id: ID!) {
  closeAppointment(_id: $id) {
    _id
    closedAt
  }
}
`
const ADD_REVIEW_APPOINTMENT = gql`
  mutation reviewAppointment($_id: ID!, $input: AppointmentReviewInput!) {
    reviewAppointment(_id: $_id, input: $input){
      review{
        professional{
          issues
          comment
        }
      }
    }
  }
`

const DELETE_UPLOAD = gql`
  query($key: String!) {
    uploadDeleteUrl(key: $key)
  }
`

export {
  ATTEND_APPOINTMENT,
  CLOSE_APPOINTMENT,
  ADD_REVIEW_APPOINTMENT,
  DELETE_UPLOAD
}