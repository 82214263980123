import { gql } from 'apollo-boost'

const GET_APPOINTMENT = gql`
  query($id: ID!) {
    appointment(_id: $id) {
      _id
      patient {
        _id
        firstname
        lastname
        email
        phone
        birthdate
      }
      review {
        professional {
          comment
          issues
        }
      }
      room
      attendedAt
    }
  }
`

const GET_ROOM_TOKEN = gql`
  query($username: String!, $room: String!) {
    roomToken(identity: $username, room: $room)
  }
`

const DELETE_UPLOAD = gql`
  query($key: String!) {
    uploadDeleteUrl(key: $key)
  }
`

export { GET_APPOINTMENT, GET_ROOM_TOKEN, DELETE_UPLOAD }
