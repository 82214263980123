import React, { useState, useEffect, useContext, useMemo } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { getWindowDimensions } from 'App/Helpers'
import { AblyContext } from 'App/Providers'
import { Header } from '@clinicalascondes/clc-parts'
import { LobbySubHeader, AppointmentInfo, TabsAppointments } from './Components'
import { Spin } from 'antd'
import { range } from 'ramda'
import {
  GET_APPOINTMENTS,
  GET_USER_SESSION,
  GET_CONSULTATIONS,
} from '../../Queries'
import LobbyScreenContext from './Components/Context/LobbyScreenContext'
import LogOutModal from '../../Components/LogOutModal'
import dayjs from 'dayjs'
import get from 'lodash/get'
import styles from './LobbyScreenStyles.module.css'
import * as Sentry from '@sentry/browser'

const isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)

const LobbyScreen = ({ history }) => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  )
  const [hours, setHours] = useState([])
  const [selectedAppointment, setSelectedAppointment] = useState({})
  const [isAppointmentSelected, setIsAppointmentSelected] = useState(false)
  const [entered, setEntered] = useState(false)
  const [compressedMode, setCompressedMode] = useState(
    localStorage.getItem('compressedMode'),
  )
  const [session, setSession] = useState()
  const [appInfoStatus, setAppInfoStatus] = useState('disconnected')
  const ablyState = useContext(AblyContext)

  const appointmentsProviderValue = useMemo(
    () => ({
      compressedMode,
      setCompressedMode,
    }),
    [compressedMode, setCompressedMode],
  )

  const {
    data: dataAppointments,
    loading: loadingAppointments,
  } = useQuery(GET_APPOINTMENTS, { fetchPolicy: 'network-only' })
  const {
    data: dataConsultations,
    loading: loadingConsultations,
  } = useQuery(GET_CONSULTATIONS, { fetchPolicy: 'network-only' })
  const { data: sessionData } = useQuery(GET_USER_SESSION, {
    fetchPolicy: 'network-only',
  })

  const startMeeting = () => {
    history.push(`/appointment/${selectedAppointment._id}`)
  }

  const fillListofHours = () => {
    const hours = []
    const now = dayjs()
    const dayHours = range(8, 24)
    dayHours.forEach(i => {
      for (let j = 0; j < 60; j += 5)
        hours.push(
          now
            .hour(i)
            .minute(j)
            .second(0)
            .millisecond(0),
        )
    })
    setHours(hours)
  }

  /* #region Ably connection */
  useEffect(() => {
    if (sessionData) {
      const sessions = get(sessionData, 'session')
      setSession(sessions)
      ablyState.setUserSession(sessions)
      ablyState.setConnectionId(sessions._id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionData])
  /* #endregion */

  useEffect(() => {
    if (session !== undefined) {
      const channelName1 = `wait-room:${session._id}`
      const channelName2 = `wait-room`

      ablyState.setEnterChannels([
        {
          channelName: channelName1,
        },
        {
          channelName: channelName2,
        },
      ])

      Sentry.configureScope(scope => {
        scope.setUser({ id: session._id })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session])

  useEffect(() => {
    if (
      ablyState.channels &&
      session !== undefined &&
      ablyState.waitRoomId !== null
    ) {
      const channel = ablyState.channels[`wait-room:${session._id}`]
      if (ablyState.waitRoomId.length > 1)
        channel.connection.publish('inAppointmentMessage', {
          inAppointment: false,
        })
    }
  }, [ablyState.channels, ablyState.waitRoomId, session])

  const selectAppointment = item => {
    const { appointment, time } = item
    if (appointment) {
      setSelectedAppointment({
        closedAt: appointment.closedAt,
        firstName: appointment.patient.firstname,
        lastName: appointment.patient.lastname,
        date: dayjs(),
        time,
        rut: appointment.patient.rut,
        patientId: appointment.patient._id,
        done: !!appointment.closedAt,
        _id: appointment._id,
        year: appointment.year,
        month: appointment.month,
        day: appointment.day,
      })
      setIsAppointmentSelected(true)
    }
  }

  const cancelModalAppointment = () => {
    setIsAppointmentSelected(false)
  }

  useEffect(() => {
    fillListofHours()
    const handleResize = () => setWindowDimensions(getWindowDimensions())
    window.addEventListener('resize', handleResize)
    localStorage.setItem('compressedMode', false)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return dataAppointments && dataConsultations ? (
    <>
      <Header
        labelLogo="Telemedicina"
        rightComponent={
          session && (
            <LogOutModal
              username={`${session.firstname} ${session.lastname}`}
            />
          )
        }
      />
      <LobbySubHeader appointments={dataAppointments.todayAppointments} />
      <div className={styles.contentRow}>
        <LobbyScreenContext.Provider value={appointmentsProviderValue}>
          <TabsAppointments
            hours={hours}
            appointments={dataAppointments.todayAppointments}
            consultations={dataConsultations.todayConsultations}
            session={session}
            entered={entered}
            setEntered={setEntered}
            windowDimensions={windowDimensions}
            loading={loadingAppointments && loadingConsultations}
            handleClick={selectAppointment}
            ablyState={ablyState}
            realtime={ablyState.realtime}
            history={history}
            setAppInfoStatus={setAppInfoStatus}
          />
        </LobbyScreenContext.Provider>
        <AppointmentInfo
          appointment={selectedAppointment}
          windowDimensions={windowDimensions}
          isAppointmentSelected={isAppointmentSelected}
          session={session}
          startMeeting={startMeeting}
          cancelModalAppointment={cancelModalAppointment}
          ablyState={ablyState}
          appInfoStatus={appInfoStatus}
        />
      </div>
    </>
  ) : (
    <Spin size="large" style={{ position: 'fixed', top: '50%', left: '50%' }} />
  )
}

export default LobbyScreen
