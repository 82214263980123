import React from 'react'

import { Row, Col, Typography } from 'antd'

import styles from './callFiles.module.css'

import { CallFilesProvider } from 'App/Screens/AppointmentScreen/Components/Components/CallFiles/Provider'
import { Files } from './Components'

const CallFiles = ({ room, isShowFiles }) => {
  return isShowFiles ? (
    <CallFilesProvider>
      <Row className={styles.containerCallFiles}>
        <Col className={styles.callFiles}>
          <Row>
            <Col span={24} className={styles.callFilesHeader}>
              <Typography className={styles.callFilesHeaderTitle}>
                Archivos Cargados:
              </Typography>
            </Col>
            <Col span={24} className={styles.callFilesBody}>
              <Files room={room} />
            </Col>
          </Row>
        </Col>
      </Row>
    </CallFilesProvider>
  ) : (
    ''
  )
}

export default CallFiles
