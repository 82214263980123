import React, { useEffect, useReducer } from 'react'
import { Tag, Spin } from 'antd'

import AttachmentIcon from '../../../../../Assets/SVG/AttachmentIcon'

import { useQuery } from '@apollo/react-hooks'
import { GET_UPLOADS } from '../../../../../Queries'
import get from 'lodash/get'

const switchAppointments = (state, action) => {
  switch (action.type) {
    case 'UPDATE_APPOINTMENT':
      return {
        appointment: action.appointment,
      }

    default:
      return {
        appointment: action.appointment,
      }
  }
}

const TagFiles = ({ item, styles, ablyState }) => {

  const { realtime } = ablyState
  const initialState = {
    appointment: item.appointment,
  }

  const [state, dispatch] = useReducer(switchAppointments, initialState)

  const GET_UPLOADS_QUERY = useQuery(GET_UPLOADS, {
    variables: { room: item.appointment === undefined ? '' : item.appointment.room },
    skip: item.appointment === undefined
  })

  useEffect(() => {
    if (realtime && item.appointment !== undefined) {
      const { room } = item.appointment
      const channel = realtime.channels.get(`filesManagement:${room}`)
      channel.subscribe('update', () => {
        GET_UPLOADS_QUERY.startPolling(1000)
      })
    }
  }, [GET_UPLOADS_QUERY, item.appointment, realtime])

  const uploads = get(GET_UPLOADS_QUERY.data, 'uploads')

  useEffect(() => {
    const app = state.appointment
    app.uploads = uploads
    dispatch({
      type: 'UPDATE_APPOINTMENT',
      appointment: app,
    })
  }, [state.appointment, uploads])

  if (GET_UPLOADS_QUERY.loading) return <Spin />

  if (GET_UPLOADS_QUERY.error) return <span>Error</span>

  if (state.appointment.uploads !== undefined) {
    GET_UPLOADS_QUERY.stopPolling()
    return (
      <Tag className={styles.tagListItem} style={{ cursor: 'pointer' }}>
        <div
          className={styles.taglistContent}
          style={{ color: '#6B7CFF', fontWeight: '600' }}>
          <AttachmentIcon width="15px" height="15px" fill="#6B7CFF" />
          <div className={styles.tagLabel} style={{ marginLeft: '8px' }}>
            {state.appointment.uploads.length} Archivos Cargados
          </div>
        </div>
      </Tag>
    )
  } else return <Spin />
}

export default TagFiles
