import React, { useState, useEffect } from 'react'
import { Row, Col, Alert, Button } from 'antd'
import PermissionImage from 'App/Assets/Images/testPermission.png'
import styles from 'App/Screens/LobbyScreen/LobbyScreenStyles.module.css'

const ErrorTestModal = ({ customMessage, isMobile }) => {
  const [isSafari, setIsSafari] = useState(false)
  const [iosMobile, setIosMobile] = useState(false)

  const safariBrowser = async () => {
    const ua = await navigator.userAgent.toLowerCase()
    if (ua.indexOf('safari') !== -1)
      if (ua.indexOf('chrome') <= -1) setIsSafari(true)
  }

  useEffect(() => {
    safariBrowser()
    const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream
    const aspect = window.screen.width / window.screen.height
    if (iPhone && aspect.toFixed(3) === '0.462') setIosMobile(true)
    else setIosMobile(false)

    // iphone X or iphone 11
  }, [isMobile])

  return (
    <Row
      justify="center"
      style={{ padding: '20px', width: '100%', height: '100%' }}>
      <Col span={24} style={{ padding: '10px 0px' }}>
        <Alert
          message="ALERTA"
          description={customMessage}
          type="error"
          className={styles.animationAlert}
          showIcon
          closable
        />
      </Col>
      <Col span={24} style={{ textAlign: 'center' }}>
        {isSafari ? (
          isMobile ? (
            <>
              <p>
                En la parte superior izquierda, presiona{' '}
                <span style={{ fontWeight: 'bold' }}>
                  A<span style={{ fontSize: 18 }}>A</span>
                </span>{' '}
                {'>'}{' '}
                <span style={{ fontWeight: 'bold' }}>
                  Configuración de sitios
                </span>{' '}
                {'>'}
              </p>
              <p>
                <span style={{ fontWeight: 'bold' }}>
                  Permitir cámara y micrófono.
                </span>
              </p>
            </>
          ) : iosMobile ? (
            <>
              <p>
                En la parte superior izquierda, presiona{' '}
                <span style={{ fontWeight: 'bold' }}>
                  A<span style={{ fontSize: 18 }}>A</span>
                </span>{' '}
                {'>'}{' '}
                <span style={{ fontWeight: 'bold' }}>
                  Configuración de sitios
                </span>{' '}
                {'>'}
              </p>
              <p>
                <span style={{ fontWeight: 'bold' }}>
                  Permitir cámara y micrófono.
                </span>
              </p>
            </>
          ) : (
            <>
              <p>
                Elige <span style={{ fontWeight: 'bold' }}>Safari</span> {'>'}{' '}
                <span style={{ fontWeight: 'bold' }}>
                  Ajustes para este sitio web
                </span>{' '}
                {'>'}
              </p>
              <p>
                <span style={{ fontWeight: 'bold' }}>
                  Permitir cámara y micrófono.
                </span>
              </p>
            </>
          )
        ) : (
          <img
            src={PermissionImage}
            alt="Permission"
            width="100%"
            style={{ objectFit: 'cover' }}
          />
        )}
      </Col>
      <Col span={24} style={{ paddingTop: '20px' }}>
        <Button
          type="ghost"
          shape="round"
          size={'large'}
          style={{ width: '100%', fontSize: '1.1vw' }}
          className={styles.refreshButtonTestCamera}
          onClick={() => window.location.reload(true)}>
          Refrescar Ventana
        </Button>
      </Col>
    </Row>
  )
}

export default ErrorTestModal
