import * as React from 'react'

function ShareScreenIcon(props) {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      width={props.width}
      height={props.height}
      full={props.fill}
      {...props}>
      <g>
        <g>
          <path
            d="M426.667,384c23.467,0,42.453-19.2,42.453-42.667L469.333,128c0-23.68-19.2-42.667-42.667-42.667H85.333
			c-23.68,0-42.667,18.987-42.667,42.667v213.333C42.667,364.8,61.653,384,85.333,384H0v42.667h512V384H426.667z M277.333,308.693
			v-46.72c-59.307,0-98.347,18.133-128,58.027c11.947-56.96,45.013-113.707,128-125.227v-45.44l85.333,79.573L277.333,308.693z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  )
}

export default ShareScreenIcon
