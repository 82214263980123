import React, { useState, useEffect, useRef } from 'react'

import styles from '../../AppointmentScreenStyles.module.css'

const Participant = ({ participant, isOnline }) => {
  const [videoTracks, setVideoTracks] = useState([])
  const [audioTracks, setAudioTracks] = useState([])

  const videoRef = useRef()
  const audioRef = useRef()

  const trackpubsToTracks = trackMap =>
    Array.from(trackMap.values())
      .map(publication => publication.track)
      .filter(track => track !== null)

  useEffect(() => {
    const trackSubscribed = track => {
      track.kind === 'video'
        ? setVideoTracks(videoTracks => [...videoTracks, track])
        : setAudioTracks(audioTracks => [...audioTracks, track])
    }

    const trackUnsubscribed = track => {
      track.kind === 'video'
        ? setVideoTracks(videoTracks => videoTracks.filter(v => v !== track))
        : setAudioTracks(audioTracks => audioTracks.filter(a => a !== track))
    }

    setVideoTracks(trackpubsToTracks(participant.videoTracks))
    setAudioTracks(trackpubsToTracks(participant.audioTracks))

    participant.on('trackSubscribed', trackSubscribed)
    participant.on('trackUnsubscribed', trackUnsubscribed)

    return () => {
      setVideoTracks([])
      setAudioTracks([])
      participant.removeAllListeners()
    }
  }, [participant])

  useEffect(() => {
    const audioTrack = audioTracks[0]
    if (audioTrack) {
      audioTrack.attach(audioRef.current)
      return () => {
        audioTrack.detach()
      }
    }
  }, [audioTracks])

  useEffect(() => {
    const videoTrack = videoTracks[0]
    if (videoTrack) {
      videoTrack.attach(videoRef.current)
      return () => {
        videoTrack.detach()
      }
    }
  }, [videoTracks])

  if (!isOnline)
    return (
      <div
        className={styles.participant}
        style={{ position: 'relative', top: '35%' }}>
        <p className={styles.waitingDoctor}>
          El paciente aún no se conecta a tu sala...
        </p>
        <p>Espera un poco mientras se conecta.</p>
      </div>
    )

  return (
    <div className={styles.participant}>
      <video className={styles.participantVideo} ref={videoRef} autoPlay />
      <audio ref={audioRef} autoPlay />
    </div>
  )
}

export default Participant
