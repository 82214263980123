import React, { useState, useEffect } from 'react'
import { Button, Modal, Tag } from 'antd'
import { withRouter } from 'react-router-dom'
import styles from '../LobbyScreenStyles.module.css'
import dayjs from 'dayjs'

const TagStatus = ({ color, label }) => {
  return (
    <Tag className={styles.tagListItem}>
      <div className={styles.taglistContent}>
        <div className={styles.tagDot} style={{ backgroundColor: color }}></div>
        <div>{label}</div>
      </div>
    </Tag>
  )
}

const AppointmentInfo = ({
  appointment,
  isAppointmentSelected,
  cancelModalAppointment,
  startMeeting,
  ablyState,
}) => {
  const conectedColor = '#5CC531'
  const waitingColor = '#EFB041'
  const disconectedColor = '#979797'
  const endColor = '#6A7BFF'

  const [showModal, setShowModal] = useState(false)
  const [connectionStatus, setConnectionStatus] = useState('disconnected')

  useEffect(() => {
    if (isAppointmentSelected) setShowModal(true)
  }, [isAppointmentSelected])

  useEffect(() => {
    if (appointment !== undefined && ablyState.waitRoomId !== null) {
      const { closedAt } = appointment

      if (closedAt) setConnectionStatus('done')
      else {
        const isOnline = ablyState.waitRoomId.find(p => {
          if (!appointment) return false
          return p.clientId === appointment.patientId
        })

        if (isOnline && isOnline.data === 'waiting')
          setConnectionStatus('waiting')
        else setConnectionStatus(isOnline ? 'connected' : 'disconnected')
      }
    }
  }, [ablyState.waitRoomId, appointment])

  const date = dayjs().format('dddd D [de] MMMM')
  const parsedDate = `${date.charAt(0).toUpperCase()}${date.substring(1)}`

  return (
    <>
      {isAppointmentSelected ? (
        <Modal
          title="Cita"
          visible={showModal}
          footer={null}
          onCancel={cancelModalAppointment}>
          <div className={styles.card}>
            <div className={styles.cardHeader}>
              <div className={styles.cardPatientData}>
                {appointment.firstName} {appointment.lastName}
              </div>

              <div>
                {connectionStatus === 'waiting' ? (
                  <TagStatus label="Esperando" color={waitingColor} />
                ) : connectionStatus === 'connected' ? (
                  <TagStatus label="Conectado" color={conectedColor} />
                ) : connectionStatus === 'disconnected' ? (
                  <TagStatus label="Desconectado" color={disconectedColor} />
                ) : (
                  <TagStatus label="Finalizado" color={endColor} />
                )}
              </div>
            </div>
            <div className={styles.cardBody}>
              <div className={styles.cardText}>{parsedDate}</div>
              <div className={styles.cardText}>{`${appointment.time}hrs.`}</div>
              <div className={styles.cardButtonContainers}>
                <div className={styles.cardCancelModal}>
                  <Button onClick={cancelModalAppointment}>Cerrar</Button>
                </div>
                <div className={styles.cardStartMeet}>
                  <Button type="ghost" block onClick={startMeeting}>
                    Ingresar
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  )
}

export default withRouter(AppointmentInfo)
