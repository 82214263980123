import React from 'react'
import { Checkbox } from 'antd'

const StepTwoContent = ({ stepTwoOpinions, stepTwoOptions, handleStepTwoOptions }) => {
  return (

    <Checkbox.Group
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: '3vh 0vh',
      }}
      defaultValue={stepTwoOpinions}
      options={stepTwoOptions}
      onChange={handleStepTwoOptions}
    />
  )
}

export default StepTwoContent