import React from 'react'
import { Tag } from 'antd'
import CallFiles from './CallFiles'
import styles from '../../AppointmentScreenStyles.module.css'

const CallCard = ({ patient, isConnected, room, isShowFiles }) => {
  return (
    <div className={styles.callCard}>
      <div className={styles.callCardContainer}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h3
              className={styles.callCardNamePatient}
              style={{ color: '#2D3F8C' }}>
              {patient.firstname} {patient.lastname}
            </h3>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tag className={styles.tagListItem} style={{ cursor: 'pointer' }}>
              <div className={styles.taglistContent}>
                <div
                  className={styles.tagDot}
                  style={{
                    backgroundColor: isConnected ? '#5CC531' : '#F6AF43',
                  }}></div>
                <div className={styles.tagLabel} style={{ color: '#707070' }}>
                  {isConnected ? 'En la Sala' : 'Fuera de la Sala'}
                </div>
              </div>
            </Tag>
          </div>
        </div>
      </div>
      <CallFiles room={room} isShowFiles={isShowFiles} />
    </div>
  )
}

export default CallCard
