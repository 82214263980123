import React, { useState } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Screens from './Screens'
import Root from './Root'
import 'antd/dist/antd.css'
import 'typeface-roboto'
import 'typeface-inter'
import { ablyConnection } from './Helpers'

import { ApolloApp, AblyProvider, IntercomApp } from './Providers'
import { Client } from './ApolloClient'

export default function App() {
  const [realtime, setRealtime] = useState(null)
  return (
    <ApolloApp client={Client}>
      <AblyProvider>
        <Router>
          <ablyConnection.Provider value={{ realtime, setRealtime }}>
            <IntercomApp>
              <Route component={Root}>{Screens}</Route>
            </IntercomApp>
          </ablyConnection.Provider>
        </Router>
      </AblyProvider>
    </ApolloApp>
  )
}
