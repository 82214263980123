import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { LoginScreen } from '@clinicalascondes/clc-parts'
import AppLayout from './AppLayout'
import AppointmentScreen from './AppointmentScreen'
import LobbyScreen from './LobbyScreen'
import Amplify from 'aws-amplify'
import AblyCardPopUp from './AblyCardPopUp'

const config = require('App/AmplifyConfig/config')
Amplify.configure(config)

const AppLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <AppLayout>
          <Component {...matchProps} />
        </AppLayout>
      )}
    />
  )
}

const Screens = () => {
  return (
    <AblyCardPopUp>
      <Switch>
        <Route path="/login" component={props => {
          return <LoginScreen label="Profesionales" path="lobby" {...props} />
        }} />
        <AppLayoutRoute path="/appointment/:_id" component={AppointmentScreen} />
        <AppLayoutRoute path="/lobby" component={LobbyScreen} />
        <AppLayoutRoute path="/" component={() => <Redirect to={'/lobby'} />} />
        <Route component={() => <h1>404</h1>} />
      </Switch>
    </AblyCardPopUp>
  )
}

export default Screens
