import React, { useState } from 'react'
import { Tabs } from 'antd'

import PatientHeaderInfo from '../PatientHeaderInfo'
import {
    ConsultasComponent
} from '../PatientList'

const { TabPane } = Tabs

const TabsAppointments = ({
    appointments,
    consultations,
    loading,
    handleClick,
    hours,
    session,
    entered,
    history,
    ablyState,
    setAppInfoStatus
}) => {

    const [isConsultation, setIsConsultation] = useState(true)

    return (
        <div style={{ position: 'relative' }}>
            <Tabs defaultActiveKey={'1'} type="card" className="card-container" onTabClick={e => {
                setIsConsultation(e === '1')
            }}>
                <TabPane tab="CONSULTAS" key='1'>
                    <ConsultasComponent appointments={appointments}
                        loading={loading}
                        handleClick={handleClick}
                        hours={hours}
                        session={session}
                        entered={entered}
                        history={history}
                        ablyState={ablyState}
                        setAppInfoStatus={setAppInfoStatus} />
                </TabPane>
            </Tabs>
            <div style={{ position: 'absolute', top: 0, right: 0 }}>
                <PatientHeaderInfo appointments={appointments}
                    consultations={consultations}
                    ablyState={ablyState}
                    isConsultation={isConsultation} />
            </div>
        </div>
    )
}

export default TabsAppointments
