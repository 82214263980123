import React, { createContext, useState } from 'react'

export const CallFilesContext = createContext({
  uploadFile: null,
  deletingFile: null,
  processingFile: null,
  files: null,
  setUploadFile: () => {},
  setDeletingFile: () => {},
  setProcessingFile: () => {},
  setFiles: () => {},
})

export const CallFilesProvider = ({ children }) => {
  const [uploadFile, setUploadFile] = useState(false)
  const [deletingFile, setDeletingFile] = useState(false)
  const [processingFile, setProcessingFile] = useState(false)
  const [files, setFiles] = useState(0)

  return (
    <CallFilesContext.Provider
      value={{
        uploadFile,
        deletingFile,
        processingFile,
        files,
        setUploadFile,
        setDeletingFile,
        setProcessingFile,
        setFiles,
      }}>
      {children}
    </CallFilesContext.Provider>
  )
}
