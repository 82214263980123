import { useQuery } from 'react-apollo'
import { GET_UPLOADS } from 'App/Queries'

import { AblyContext } from 'App/Providers'
import { CallFilesContext } from 'App/Screens/AppointmentScreen/Components/Components/CallFiles/Provider'
import { useContext, useEffect } from 'react'

const useGetUploads = room => {
  const { setDeletingFile, setProcessingFile } = useContext(CallFilesContext)
  const { realtime } = useContext(AblyContext)

  const { data, loading, error, refetch } = useQuery(GET_UPLOADS, {
    variables: { room },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (realtime) {
      const channel = realtime.channels.get(`filesManagement:${room}`)
      channel.subscribe('update', () => {
        setProcessingFile(false)
        setDeletingFile(false)
        refetch()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realtime])

  return {
    data,
    loading,
    error,
    refetch,
  }
}

export default useGetUploads
