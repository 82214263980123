import React, { useEffect } from 'react'
import { Modal, Row, Col, Spin } from 'antd'
import styles from '../../../LobbyScreenStyles.module.css'

import { useQuery } from '@apollo/react-hooks'

import {
  GET_UPLOAD_FETCH_URL
} from '../../../../../Queries'


const FileData = ({ uploads, setLoadingFiles, ind, length }) => {

  const { data, loading } = useQuery(GET_UPLOAD_FETCH_URL, {
    variables: { key: uploads.key }
  })

  useEffect(() => {
    if (ind === length)
      setLoadingFiles(false)

  }, [ind, length, setLoadingFiles])

  if (loading)
    return (
      <Spin></Spin>
    )


  if (data)
    return (
      <span>
        <a href={data.uploadFetchUrl}
          target='_blank' rel='noopener noreferrer'>
          Archivo {ind + 1}
        </a>
      </span>
    )

}

const PatientFiles = ({
  showFiles,
  ShowFilesModal,
  appointment,
  loadingFiles,
  setLoadingFiles,
  ablyState,
  resetCounter
}) => {

  return (
    <>
      {showFiles ? (
        <Modal
          title="Paciente"
          visible={showFiles}
          footer={null}
          onCancel={ShowFilesModal}
          afterClose={resetCounter}
          destroyOnClose>
          <Row>
            <Col span={24} style={{ padding: '10px 0px' }}>
              <Row>
                <Col span={12}>
                  <h3 className={styles.attachmentFilesPatient}>
                    {appointment.patient.firstname}{' '}
                    {appointment.patient.lastname}
                  </h3>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                  <h3 className={styles.attachmentFilesPatient}>
                    {appointment.patient.rut
                      ? appointment.patient.rut
                      : appointment.patient.passport}
                  </h3>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={24} style={{ padding: '10px 0px' }}>
                  <h4 style={{ color: '#6B7CFF' }}>Archivos Cargados:</h4>
                </Col>
                <Col span={24} style={{ padding: '0px 10px' }}>
                  <div className={styles.attachmentFilesContainer}>
                    {
                      appointment.uploads !== undefined ? (
                        appointment.uploads.map((element, key) => {
                          return (
                            <FileData key={key}
                              uploads={element}
                              ind={key}
                              length={appointment.uploads.length - 1}
                              setLoadingFiles={setLoadingFiles} />
                          )
                        })
                      ) : ('')
                    }
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal>
      ) : null}
    </>
  )
}

export default PatientFiles
