import * as React from 'react'

function CameraSwitchIcon(props) {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      width={props.width}
      height={props.height}
      fill={props.fill}
      x="0px"
      y="0px"
      viewBox="0 0 480 480"
      xmlSpace="preserve"
      {...props}>
      <g>
        <g>
          <g>
            <path
              d="M440,116v-16c0-4.418-3.582-8-8-8h-48c-4.418,0-8,3.582-8,8v16h-19.352L336.16,80.16
				c-7.132-12.452-20.378-20.142-34.728-20.16H178.568c-14.35,0.018-27.596,7.708-34.728,20.16L123.352,116H120V92
				c0-4.418-3.582-8-8-8H48c-4.418,0-8,3.582-8,8v24c-22.08,0.026-39.974,17.92-40,40v224c0.026,22.08,17.92,39.974,40,40h400
				c22.08-0.026,39.974-17.92,40-40V156C479.974,133.92,462.08,116.026,440,116z M392,108h32v8h-32V108z M56,100h48v16H56V100z
				M464,380c0,13.255-10.745,24-24,24H40c-13.255,0-24-10.745-24-24V156c0-13.255,10.745-24,24-24h88
				c2.864,0.006,5.512-1.52,6.944-4l22.784-39.872c4.275-7.481,12.223-12.107,20.84-12.128h122.864
				c8.596,0.029,16.524,4.64,20.8,12.096L345.056,128c1.432,2.48,4.08,4.006,6.944,4h88c13.255,0,24,10.745,24,24V380z"
            />
            <path
              d="M347.532,284.504c-0.007-0.003-0.013-0.005-0.02-0.008c-4.127-1.577-8.75,0.49-10.328,4.616
				c-20.569,53.628-80.718,80.428-134.346,59.859c-36.074-13.836-61.504-46.53-66.038-84.899l19.528,19.528
				c3.07,3.178,8.134,3.266,11.312,0.196c3.178-3.07,3.266-8.134,0.196-11.312c-0.064-0.067-0.13-0.132-0.196-0.196l-33.944-33.944
				c-3.124-3.123-8.188-3.123-11.312,0L88.4,272.288c-3.07,3.178-2.982,8.242,0.196,11.312c3.1,2.995,8.016,2.995,11.116,0
				l20.8-20.8c5.983,66.004,64.339,114.66,130.343,108.677c45.602-4.133,84.876-33.857,101.241-76.622
				C353.694,290.737,351.651,286.102,347.532,284.504z"
            />
            <path
              d="M380.484,220.204c-0.067,0.064-0.132,0.13-0.196,0.196l-20.8,20.8c-5.97-66.005-64.317-114.672-130.322-108.702
				c-45.794,4.142-85.195,34.088-101.446,77.102c-1.562,4.133,0.523,8.75,4.656,10.312c4.133,1.562,8.75-0.523,10.312-4.656
				c20.341-53.715,80.375-80.771,134.09-60.431c36.31,13.75,61.938,46.581,66.462,85.143L323.712,220.4
				c-3.069-3.178-8.134-3.266-11.312-0.196c-3.178,3.069-3.266,8.134-0.196,11.312c0.064,0.067,0.13,0.132,0.196,0.196
				l33.944,33.944c3.124,3.123,8.188,3.123,11.312,0l33.944-33.944c3.178-3.069,3.266-8.134,0.196-11.312
				C388.727,217.222,383.662,217.134,380.484,220.204z"
            />
          </g>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  )
}

export default CameraSwitchIcon
