import React, { useState, useEffect } from 'react'
import localization from 'dayjs/locale/es'
import dayjs from 'dayjs'
import styles from '../LobbyScreenStyles.module.css'
import { Row, Col } from 'antd'

const LobbySubHeader = ({ appointments }) => {
  const colorBlue = '#6B7CFF'
  const [numberOfAppointments, setNumberOfAppointments] = useState(0)

  useEffect(() => {
    setNumberOfAppointments(
      appointments.length,
    )
  }, [appointments])

  return (
    <div style={{ background: '#F9F9F9' }}>
      <Row justify="center">
        <Col lg={8}>
          <div className={styles.subHeaderText}>
            <p className={styles.date}>
              Hoy{' '}
              {dayjs()
                .locale('es', localization)
                .format(`dddd,`)}
              <span style={{ color: colorBlue, marginLeft: '5px' }}>
                {dayjs()
                  .locale('es', localization)
                  .format(`D [de] MMMM`)}
              </span>
            </p>
          </div>
        </Col>
        <Col lg={8} offset={8}>
          <div className={styles.subHeaderText} style={{ textAlign: 'right' }}>
            <p className={styles.consultNumber}>
              {numberOfAppointments > 1 ? 'Tienes' : 'Tiene'}{' '}
              <span style={{ color: colorBlue }}>
                {numberOfAppointments > 1 ? (
                  <>{numberOfAppointments} Consultas</>
                ) : (
                    <>{numberOfAppointments} Consulta</>
                  )}
              </span>{' '}
              {numberOfAppointments > 1 ? (
                <>programadas</>
              ) : (
                  <>programada</>
                )}
            </p>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default LobbySubHeader
