import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import dayjs from 'dayjs'

import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

const dsn = 'https://bed849d0a12d458ca6a5e80c9741a35c@o342603.ingest.sentry.io/5204739'
const environment = process.env.REACT_APP_BRANCH || 'develop'
const release = `clc-app-doctor@${process.env.REACT_APP_COMMIT_REF}`
const debug = !!process.env.REACT_APP_BRANCH === false || process.env.REACT_APP_BRANCH === 'develop'

localStorage.setItem('apolloClientToken', '')
dayjs.locale('es')
Sentry.init({
  dsn,
  release,
  environment,
  debug
})

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
