import * as React from 'react'

function CameraIconOff(props) {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={props.width}
      height={props.height}
      viewBox="0 0 484.5 484.5"
      xmlSpace="preserve"
      fill={props.fill}
      {...props}>
      <g>
        <g id="videocam-off">
          <path
            d="M484.5,114.75l-102,102V127.5c0-15.3-10.2-25.5-25.5-25.5H198.9l285.6,285.6V114.75z M33.15,0L0,33.15L68.85,102H51
			c-15.3,0-25.5,10.2-25.5,25.5v255c0,15.3,10.2,25.5,25.5,25.5h306c5.1,0,10.2-2.55,12.75-5.1l81.6,81.6l33.15-33.15L33.15,0z"
          />
        </g>
      </g>
    </svg>
  )
}

export default CameraIconOff
