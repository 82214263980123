import React, { useEffect, useState, useRef } from 'react'
import { Button, Avatar } from 'antd'
import { Header as HeaderComponent } from '@clinicalascondes/clc-parts'
import styles from '../../AppointmentScreenStyles.module.css'
import dayjs from 'dayjs'

const parseDate = date => dayjs(date).format('mm:ss')

const usePreciseTimer = (handler, periodInMilliseconds, activityFlag) => {
  const [timeDelay, setTimeDelay] = useState(1)
  const savedCallback = useRef()
  const initialTime = useRef()

  useEffect(() => {
    savedCallback.current = handler
  }, [handler])

  useEffect(() => {
    if (activityFlag) {
      initialTime.current = dayjs().valueOf()
      const id = setInterval(() => {
        const currentTime = dayjs().valueOf()
        const delay = currentTime - initialTime.current
        initialTime.current = currentTime
        setTimeDelay(delay / 1000)
        savedCallback.current(timeDelay)
      }, periodInMilliseconds)

      return () => {
        clearInterval(id)
      }
    }
  }, [periodInMilliseconds, activityFlag, timeDelay])
}

const Header = ({ endCall, isTimerVisible, username }) => {
  const [userName, setUserName] = useState(username)
  const [time, setTime] = useState(
    dayjs()
      .minute(0)
      .second(0),
  )

  useEffect(() => {
    if (username === null) setUserName(localStorage.getItem('username'))
  }, [username])

  const handleTimerChange = e => setTime(time.add(e, 's'))
  usePreciseTimer(handleTimerChange, 1000, true)
  return (
    <>
      <HeaderComponent
        middleComponent={
          <>
            {isTimerVisible && (
              <div className={styles.timeTranscurredAndEndCall}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: '20px',
                  }}>
                  <span className={styles.timeTranscurredText}>
                    Tiempo transcurrido:{' '}
                  </span>
                  <span style={{ fontWeight: 'bold', marginLeft: '8px' }}>
                    {parseDate(time)}
                  </span>
                </div>

                <div style={{ marginLeft: '20px' }}>
                  <Button
                    size="large"
                    type="primary"
                    className={styles.callHeaderStatusButton}
                    onClick={endCall}
                    danger>
                    Finalizar Consulta
                  </Button>
                </div>
              </div>
            )}
          </>
        }
        rightComponent={
          <div className={styles.userHeader}>
            <div>
              <Avatar size={40} style={{ background: '#6078FB' }}>
                {username[0]}
              </Avatar>
            </div>
            <div className={styles.userNameHeader}>{userName}</div>
          </div>
        }
      />
    </>
  )
}

export default Header
