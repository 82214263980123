import React, { useContext, useState, useCallback, useEffect } from 'react'
import styles from '../LobbyScreenStyles.module.css'

import LobbyScreenContext from './Context/LobbyScreenContext'
import { Checkbox } from 'antd'

const CompressedMode = ({ appointments }) => {
  const { setCompressedMode } = useContext(LobbyScreenContext)

  const compressedModeHandler = e => {
    setCompressedMode(e.target.checked)
  }

  return (
    <div className={styles.patientHeaderText}>
      <Checkbox
        onChange={compressedModeHandler}
        disabled={appointments.length === 0}>
        Activar modo comprimido
      </Checkbox>
    </div>
  )
}

const PatientHeaderInfo = ({
  appointments,
  consultations,
  ablyState,
  isConsultation,
}) => {
  const [finishedAppointments, setFinishedAppointments] = useState(0)
  const [pendingAppointments, setPendingAppointments] = useState(0)
  const [appoint, setAppoint] = useState([])

  useEffect(() => {
    if (isConsultation) setAppoint(appointments)
    else if (!isConsultation) setAppoint(consultations)
  }, [isConsultation, appointments, consultations])

  const patientStatus = useCallback(
    item => {
      if (ablyState.waitRoomId !== null) {
        const { closedAt } = item
        if (closedAt) return 'done'
        const isOnline = ablyState.waitRoomId.find(p => {
          if (!item.appointment) return false
          return p.clientId === item.appointment.patient._id
        })

        if (isOnline && isOnline.data === 'waiting') return 'waiting'
        return isOnline ? 'connected' : 'disconnected'
      }
    },
    [ablyState.waitRoomId],
  )

  useEffect(() => {
    const statusAppointments = appoint.map(e => ({
      ...e,
      statusConnection: patientStatus(e),
    }))

    setFinishedAppointments(
      // eslint-disable-next-line array-callback-return
      statusAppointments.filter(element => {
        if (
          element.statusConnection !== 'waiting' &&
          element.statusConnection !== 'connected' &&
          element.statusConnection !== 'disconnected'
        )
          return element
      }).length,
    )

    setPendingAppointments(
      // eslint-disable-next-line array-callback-return
      statusAppointments.filter(element => {
        if (
          element.statusConnection === 'waiting' ||
          element.statusConnection === 'connected' ||
          element.statusConnection === 'disconnected'
        )
          return element
      }).length,
    )
  }, [appoint, patientStatus, isConsultation])

  return (
    <>
      <div className={styles.patientHeaderNums}>
        <div className={styles.patientHeaderTextNums}>
          <CompressedMode appointments={appointments} />
        </div>
        <div className={styles.patientHeaderTextNums}>
          <div
            className={styles.patienHeaderDot}
            style={{ background: 'rgba(107, 124, 255, 0.69)' }}
          />
          Finalizadas
          <span style={{ fontWeight: 'bold', marginLeft: '4px' }}>
            {finishedAppointments}
          </span>
        </div>
        <div className={styles.patientHeaderTextNums}>
          <div
            className={styles.patienHeaderDot}
            style={{ background: 'red' }}
          />
          Pendientes
          <span style={{ fontWeight: 'bold', marginLeft: '4px' }}>
            {pendingAppointments}
          </span>
        </div>
      </div>
    </>
  )
}

export default PatientHeaderInfo
