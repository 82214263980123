import React, { useEffect, useRef } from 'react'
import { Tooltip } from 'antd'
import CameraSwitchIcon from 'App/Assets/SVG/CameraSwitchIcon'
import styles from '../../AppointmentScreenStyles.module.css'

const OwnVideo = ({
  participant,
  video,
  isShareScreen,
  cameraNumber,
  handleJumpingCamera,
  videoDevicesLength,
  handleCameraNumber,
  doctorAcceptSharingScreen,
}) => {
  const videoRef = useRef()

  const jumpingInCameras = () => {
    if (cameraNumber + 1 > videoDevicesLength) {
      handleCameraNumber(1)
      handleJumpingCamera(1)
    } else {
      handleCameraNumber(cameraNumber + 1)
      handleJumpingCamera(cameraNumber + 1)
    }
  }

  useEffect(() => {
    const videoTrack = video
    if (videoTrack) {
      videoTrack.attach(videoRef.current)
      return () => {
        videoTrack.detach()
      }
    }
  }, [video])

  return (
    <div className={styles.ownVideoContainer}>
      <div style={{ position: 'relative', height: '100%', width: '100%' }}>
        <video
          className={
            doctorAcceptSharingScreen
              ? styles.ownVideoElementAcceptSharingScreen
              : styles.ownVideoElement
          }
          ref={videoRef}
          autoPlay
        />
        {videoDevicesLength > 1 && (
          <div
            className={styles.ownVideoSwitchCameraElement}
            onClick={() => {
              if (!isShareScreen) jumpingInCameras()
            }}>
            <Tooltip title={isShareScreen ? 'Desactivado' : 'Cambiar Cámara'}>
              <div
                className={styles.ownVideoSwitchCamera}
                style={{
                  backgroundColor: isShareScreen ? '#b6b4b4' : 'white',
                }}>
                <CameraSwitchIcon width="23px" height="23px" fill="#6078FB" />
              </div>
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  )
}

export default OwnVideo
