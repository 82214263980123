import React, { createContext, useState, useEffect } from 'react'
import Ably from 'ably/promises'

export const AblyContext = createContext({
    realtime: null,
    connectionId: null,
    enterChannels: [],
    channels: {},
    userSession: {},
    waitRoomId: null,
    appointment: null,
    stateConnection: null,
    // setRealtime: () => { },
    // setConnectionId: () => { },
    // setChannels: () => { },
    // setEnterChannels: () => { },
    // setUserSession: () => { },
    // leavePresenceChannel: () => { },
})

export const AblyProvider = ({ children }) => {

    const [realtime, setRealtime] = useState(null)
    const [connectionId, setConnectionId] = useState(null)
    const [userSession, setUserSession] = useState({})
    const [enterChannels, setEnterChannels] = useState([])
    const [channels, setChannels] = useState({})
    const [waitRoomId, setWaitRoomId] = useState(null)
    const [appointmentRoomName, setAppointmentRoomName] = useState(null)
    const [appointmentRoomId, setAppoinmentRoomId] = useState(null)
    const [stateConnection, setStateConnection] = useState(null)

    useEffect(() => {

        const fx = async () => {
            enterChannels.forEach((item, key) => {
                const { channelName } = item

                const channelConnection = realtime.channels.get(channelName)

                channelConnection.attach(async err => {
                    if (err) return console.error('Error attaching to channel')
                    channelConnection.presence.enter('online', err => {
                        if (err) return console.error('Failed to enter channel')
                    })
                    // channelConnection.presence.subscribe(async () => {

                    setChannels(prevValue => ({
                        ...prevValue,
                        [channelName]: {
                            connection: channelConnection
                        }
                    }))

                })
            })
        }
        if (enterChannels.length > 0)
            fx()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enterChannels])

    useEffect(() => {
        if (realtime !== null && userSession !== null) {
            const channelName = `wait-room:${userSession._id}`
            if (channels[channelName] !== undefined) {
                const channelConnection = channels[channelName].connection

                channelConnection.presence.subscribe(() => {
                    channelConnection.presence.get(async (err, presenceSet) => {
                        if (err) console.error('Failed to get current users')
                        setWaitRoomId(presenceSet)
                    })
                })
            }
        }

    }, [channels, realtime, userSession])

    useEffect(() => {
        if (appointmentRoomName !== null && realtime !== null) {
            const channelName = `appointment:${appointmentRoomName}`
            if (channels[channelName] !== undefined) {
                const channelConnection = channels[channelName].connection

                channelConnection.presence.subscribe(() => {
                    channelConnection.presence.get(async (err, presenceSet) => {
                        if (err) console.error('Failed to get current users')
                        setAppoinmentRoomId(presenceSet)
                    })
                })
            }
        }
    }, [appointmentRoomName, channels, realtime])

    useEffect(() => {
        if (connectionId !== null)
            setRealtime(
                new Ably.Realtime.Promise({
                    key: process.env.REACT_APP_ABLY_API_KEY,
                    clientId: connectionId,
                }),
            )
    }, [connectionId])

    useEffect(() => {
        if (realtime !== null)
            realtime.connection.on(stateChange => {
                setStateConnection(stateChange.current)
            })

    }, [realtime])

    const leavePresenceChannel = arrayChannels => {
        if (realtime !== null)
            return new Promise((resolve, reject) => {
                let counter = arrayChannels.length
                for (const channel in arrayChannels) {
                    const channelConnection = realtime.channels.get(channel)
                    // eslint-disable-next-line
                    channelConnection.attach(err => {
                        if (err) return console.error('Could not attach to channel')
                        channelConnection.presence.leave(async err => {
                            if (err) return console.error('Could not leave channel', err)
                            delete channels[arrayChannels[channel]]
                            counter -= 1
                            if (counter === 0) {
                                setChannels(channels)
                                setEnterChannels([])
                                resolve()
                            }

                        })
                    })
                }
            })
        else
            return new Promise((resolve, reject) => resolve())

    }

    return <AblyContext.Provider value={{
        realtime,
        connectionId,
        userSession,
        channels,
        setConnectionId,
        setUserSession,
        setEnterChannels,
        leavePresenceChannel,
        waitRoomId,
        setAppointmentRoomName,
        appointmentRoomId,
        stateConnection
    }}>{children}</AblyContext.Provider>
}
